import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class SuppliersService {
  apiEndpoint = window.location.origin + '/api/suppliers';

  private _suppliers: Supplier[];
  allSuppliers$ = new ReplaySubject<Supplier[]>();

  constructor(private http: HttpClient) {
    this.reloadSuppliers();
  }

  reloadSuppliers() {
    this.http.get<Supplier[]>(this.apiEndpoint).subscribe({
      next: res => {
        this._suppliers = res;
        this.allSuppliers$.next(res);
      },
      error: err => {
        console.log('Error loading suppliers :', err);
      }
    });
  }

  getSupplier(id: number) {
    return this._suppliers.find(x => x.supplierID == id);
  }

  createSupplier(vm: Supplier) {
    return this.http.post<Supplier>(this.apiEndpoint, vm);
  }

  saveSupplier(vm: Supplier) {
    return this.http.put<Supplier>(this.apiEndpoint + '/' + vm.supplierID.toString(), vm);
  }

  confirmSendEmail(id: number) {
    const supp = this._suppliers.find(o => o.supplierID == id);
    if (!supp || (!window.confirm('Order will be emailed to ' + supp.emailAddress + '.  Are you sure?'))) {
      return false;
    }
    return true;
  }
}



export class Supplier {
  public supplierID: number;
  public code: string;
  public companyName: string;
  public contactPerson: string;
  public emailAddress: string;
  public ccEmail: string;
  public address1: string;
  public suburb: string;
  public state: string;
  public postcode: string;
  public phone: string;
  public altPhone: string;
  public isActive: boolean;
}
