import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Supplier } from '../../../services/suppliers.service';

@Component({
  selector: 'app-supplier-edit',
  templateUrl: './supplier-edit.component.html',
  styleUrls: ['./supplier-edit.component.css']
})
export class SupplierEditComponent implements OnInit {
  @Output() saveSupplier: EventEmitter<Supplier> = new EventEmitter<Supplier>();
  @Output() cancelEdit: EventEmitter<void> = new EventEmitter();

  _supplier: Supplier;
  @Input() set supplier(value) {
    this._supplier = value;
  }

  constructor() { }

  ngOnInit() {
  }

  save() {
    this.saveSupplier.emit(this._supplier);
  }

  cancel() {
    this.cancelEdit.emit();
  }

}
