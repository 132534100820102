import { Component, OnInit } from '@angular/core';
import { SuppliersService, Supplier } from '../../../services/suppliers.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {
  suppliers$: Observable<Supplier[]>;
  filteredSuppliers: Supplier[];
  currentSupplier: Supplier;
  view = 0;
  showInactive = false;

  constructor(private service: SuppliersService) { 
    this.suppliers$ = service.allSuppliers$.pipe(tap((data) => { 
      this.filteredSuppliers = this.filterSuppliers(data);
    }));
  }

  ngOnInit() {
    // this.service.getSuppliers().subscribe(res => {
    //   this._allSuppliers = res;
    //   this.filterSuppliers();
    // }, err => {
    //   alert(err.error);
    // });
  }

  filterSuppliers(all: Supplier[]) {
    return all.filter(c => c.isActive || this.showInactive);
  }

  toggleShowInactive() {
    this.showInactive = !this.showInactive;
    //this.filterSuppliers();
  }


  addNewSupplier() {
    this.currentSupplier = new Supplier();
    this.view = 1;
  }
  editSupplier(t: Supplier) {
    this.currentSupplier = Object.assign({}, t);
    this.view = 1;
  }
  onSaveSupplier(supplier: Supplier) {
    if (!supplier.supplierID) {
      this.createSupplier(supplier);
    } else {
      this.updateSupplier(supplier);
    }
  }
  onCancelEdit() {
    this.currentSupplier = null;
    this.view = 0;
  }


  //#region Private functions for saving / updating via service
  private createSupplier(supplier: Supplier) {
    this.service.createSupplier(supplier).subscribe(res => {
      this.view = 0;
      this.service.reloadSuppliers();
    }, err => {
      console.log(err);
      alert(err.error);
    });
  }

  private updateSupplier(supplier: Supplier) {
    this.service.saveSupplier(supplier).subscribe(res => {
      this.view = 0;
      this.service.reloadSuppliers();
    }, err => {
      console.log(err);
      alert(err.error);
    });
  }
  //#endregion
}
