<div class="card">

  <div class="card-header">{{!_customer.customerID ? 'New Customer' : 'Edit ' + _customer.companyName}}</div>
  <div class="card-body">
    <form #customerForm="ngForm">

      <div class="form-group">
        <label for="code">Company Name</label>
        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="_customer.companyName" required
          appAutoFocus>
      </div>

      <div class="row">
        <div class="form-group col-md-3">
          <label for="code">Code</label>
          <input type="text" class="form-control" id="code" name="code" [(ngModel)]="_customer.code">
        </div>
        <div class="form-check col-md-6 mt-3" *ngIf="(_customer.customerID)">
          <span class="switch switch-sm">
            <input type="checkbox" class="switch" id="chkActive" name="isActive" [(ngModel)]="_customer.isActive">
            <label for="chkActive">Active</label>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-8">
          <label for="contact">Contact Person</label>
          <input type="text" class="form-control" id="contact" name="contact" [(ngModel)]="_customer.contactPerson">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-8">
          <label for="contact">Email Address</label>
          <input type="email" class="form-control" id="email" name="email" [(ngModel)]="_customer.emailAddress">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-8">
          <label for="cc">CC Email</label>
          <input type="email" class="form-control" id="cc" name="cc" [(ngModel)]="_customer.ccEmail">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label for="contact">Phone</label>
          <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="_customer.phone">
        </div>
        <div class="form-group col-md-4">
          <label for="terms">Terms</label>
          <input type="text" class="form-control" id="terms" name="terms" [(ngModel)]="_customer.terms">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label for="address">Company Address</label>
          <input type="text" class="form-control" id="address" name="address" [(ngModel)]="_customer.address1">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label for="suburb">Suburb</label>
          <input type="text" class="form-control" id="suburb" name="suburb" [(ngModel)]="_customer.suburb">
        </div>
        <div class="form-group col-md-2">
          <label for="postcode">Postcode</label>
          <input type="number" class="form-control" id="postcode" name="postcode" [(ngModel)]="_customer.postcode">
        </div>
        <div class="form-group col-md-2">
          <label for="state">State</label>
          <select class="form-control" name="state" id="state" [(ngModel)]="_customer.state">
            <option value=""></option>
            <option value="ACT">ACT</option>
            <option value="NSW">NSW</option>
            <option value="NT">NT</option>
            <option value="QLD">QLD</option>
            <option value="SA">SA</option>
            <option value="TAS">TAS</option>
            <option value="VIC">VIC</option>
            <option value="WA">WA</option>
          </select>
        </div>
      </div>

      <div class="row" *ngIf="_customer.customerID">
        <div class="col-md-6">
          <h4>Product Suppliers</h4>
          <app-customer-supplier-products [customerID]="_customer.customerID"></app-customer-supplier-products>
        </div>
      </div>

      <button type="submit" class="btn btn-primary" [disabled]="!customerForm.form.valid" (click)="save()">Save
        Customer</button>
      <button type="button" class="btn btn-default ms-2" (click)="cancel()">Cancel</button>
    </form>
  </div>
</div>