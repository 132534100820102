<p-dropdown [options]="(transports$ | async)" [(ngModel)]="selected" dataKey="transportID" [autoDisplayFirst]="false" [scrollHeight]="'300px'" [style]="{width: '100%', 'border-radius': '0', 'border-color': '#ced4da' }" (onChange)="changed()">
    <ng-template pTemplate="selectedItem">
        <div *ngIf="selected">
            <div>{{ selected.companyName }}</div>
        </div>
    </ng-template>
    <ng-template let-trans pTemplate="item">
        <div>
            <div>{{ trans.companyName }}</div>
        </div>
    </ng-template>
</p-dropdown>