<div class="card">

  <div class="card-header">{{!_product.productID ? 'New Product' : 'Edit Product'}}</div>
  <div class="card-body">
    <form #productForm="ngForm">
      <div class="row">
        <div class="form-group col-md-4">
          <label for="code">Product Code</label>
          <input type="text" class="form-control" id="code" name="code" [(ngModel)]="_product.code" required
            appAutoFocus>
        </div>
        <div class="form-group col-md-4 ml-2" *ngIf="_product.productID">
          <label>Product Active</label>
          <div class="switch switch-sm">
            <input type="checkbox" name="isActive" class="switch" id="isActive" [(ngModel)]="_product.isActive">
            <label for="isActive"></label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <input type="text" class="form-control" id="description" name="description" [(ngModel)]="_product.description"
          required>
      </div>
      <div class="row">
        <div class="form-group col-sm-3">
          <label for="sellPrice">Sell Price ($/L)</label>
          <input type="number" class="form-control" id="sellPrice" name="sellPrice" [(ngModel)]="_product.sellPrice">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-3">
          <label for="sellPrice">Buy Price ($/L)</label>
          <input type="number" class="form-control" id="buyPrice" name="buyPrice" [(ngModel)]="_product.buyPrice"
            required>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-3">
          <label for="excise">Excise ($/L)</label>
          <input type="number" class="form-control" id="excise" name="excise" [(ngModel)]="_product.excise" required>
        </div>
      </div>
      <div class="row" *ngIf="_product.productID">
        <div class="col-md-6 col-lg-4">
          <h4>Product Suppliers</h4>
          <app-product-suppliers [productID]="_product.productID"></app-product-suppliers>
        </div>
      </div>
      <div class="mt-2">
        <button type="submit" class="btn btn-primary" [disabled]="!productForm.form.valid" (click)="save()">Save
          Product</button>
        <button type="button" class="btn btn-default ms-2" (click)="cancel()">Cancel</button>
      </div>
    </form>
  </div>
</div>