import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class ProductsService {
  apiEndpoint = window.location.origin + '/api/products';

  
  private _items: Product[];
  allProducts$ = new ReplaySubject<Product[]>();
  loading$ = new BehaviorSubject<boolean>(true);


  constructor(private http: HttpClient) { 
    this.reload();
  }

  reload() {
    this.http.get<Product[]>(this.apiEndpoint).subscribe(res => {
      this._items = res;
      this.allProducts$.next(this._items);
    },error => {
      console.log(error);
    });
  }

  getProduct(id: number) {
    return this._items.find(x => x.productID == id);
  }

  createProduct(vm: Product) {
    this.http.post<Product>(this.apiEndpoint, vm).subscribe(res => {
      this.reload();
    }, error => {
      console.log(error);
      alert(error.error || error); 
    });
  }

  saveProduct(vm: Product) {
    this.http.put<Product>(this.apiEndpoint + '/' + vm.productID.toString(), vm).subscribe(() => {
      this.reload();
    }, error => {
      console.log(error);
      alert(error.error || error); 
    });
  }
}

export class Product {
  public productID: number;
  public code: string;
  public description: string;
  public isActive: boolean;
  public sellPrice: number;
  public buyPrice: number;
  public excise: number;

}

