<div class="card" *ngIf="_order">

  <div class="card-header">{{!_order.orderID ? 'New Order' : 'Edit CWFO PO'}}</div>
  <div class="card-body">
    <ngx-loading [show]="loading"></ngx-loading>
    <form #orderForm="ngForm">
      <fieldset [disabled]="_order.isCancelled">
        <div class="row">
          <div class="form-group col-md-3 col-lg-2">
            <label for="po">CWFO PO Number</label>
            <input type="text" class="form-control" id="po" name="po" value="[New Order]" *ngIf="!_order.orderID" readonly>
            <input type="text" class="form-control" id="po" name="po" [value]="_order.orderID" *ngIf="_order.orderID" readonly>
          </div>
        </div>

        <div class="card border-1 p-2 mb-2 noshadow" style="background-color: #f7f7f7;">
          <div class="table-responsive">
            <table class="table table-sm table-bordered" style="min-width: 650px">
              <thead class="thead-light">
                <tr>
                  <th style="width: 250px">Customer</th>
                  <th style="width: 150px">Customer PO</th>
                  <th style="width: 150px">Quantity</th>
                  <th style="width: 150px">Sell Price</th>
                  <th style="width: 120px" class="text-center">Document</th>
                  <th style="width: 30px"></th>
                  <th style="width: 150px">QB Invoice</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let custItem of _order.customerOrders; let idx = index">
                  <td>
                    <app-select-customer [(customerID)]="custItem.customerID" (selectedCustomer)="onCustomerSelected($event)"></app-select-customer>
                  </td>
                  <td>
                    <input type="text" class="form-control" id="custPO" name="custPO-{{idx}}" [(ngModel)]="custItem.customerPONumber">
                  </td>
                  <td>
                    <input type="number" class="form-control" id="custQty" name="custQty-{{idx}}" [(ngModel)]="custItem.quantity" required>
                  </td>
                  <td>
                    <input type="number" class="form-control" id="sellPrice" name="sellPrice-{{idx}}" [(ngModel)]="custItem.sellPrice" required>
                  </td>
                  <td class="text-center pt-1">
                    <a [href]="custItem.documentDownloadURL" target="_blank" *ngIf="custItem.documentDownloadURL" class="custom-file-upload mr-1">View</a>
                    <label for="file-upload-{{idx}}" class="custom-file-upload" *ngIf="!_newOrder">
                      <span *ngIf="!custItem.documentDownloadURL">Upload</span>
                      <span *ngIf="custItem.documentDownloadURL">Replace</span>
                    </label>
                    <input id="file-upload-{{idx}}" name="file-upload-{{idx}}" type="file" (change)="handleFileInput($any($event.target).files, custItem.orderCustomerItemID)"
                    />
                  </td>
                  <td>
                    <button class="btn btn-sm btn-default" (click)="removeCustomer(idx)">
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </td>
                  <td>
                    <input type="text" class="form-control" id="qb-{{idx}}" name="qb-{{idx}}" [(ngModel)]="custItem.qbInvoiceNumber">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-2">
              <button class="btn btn-sm btn-primary" (click)="addCustomer()">Add Customer</button>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-12 col-md-7 no-gutters">
            <div class="row">
              <!-- SUPPLIER -->
              <div class="form-group col-12 col-md-6">
                <label for="supplier">Supplier</label>
                <app-select-supplier [(supplierID)]="_order.supplierID" (selectedSupplier)="onSupplierSelected($event)" [customerID]="singleCustomerID"></app-select-supplier>
              </div>
              <div class="form-group col-md-3 col-12">
                <label for="suppInvoice">Supplier Invoice #</label>
                <input type="text" class="form-control" id="suppInvoice" name="suppInvoice" [(ngModel)]="_order.supplierInvoiceNumber">
              </div>
            </div>

            <div class="row" *ngIf="_order.supplierID">
              <!-- PRODUCT -->
              <div class="form-group col-md-6 col-12">
                <label for="product">Fuel Type</label>
                <app-select-product [customerID]="singleCustomerID" [(productID)]="_order.productID" [supplierID]="_order.supplierID" (selectedProduct)="onProductSelected($event)"></app-select-product>
              </div>
              <div class="form-group col-md-2 col-4">
                <label for="qty">
                  <span class="d-none d-sm-inline-block d-lg-none">Total Qty</span>
                  <span class="d-sm-none d-lg-inline-block">Total Quantity</span>
                </label>
                <input class="form-control" id="qty" name="qty" [ngModel]="calcTotal()" readonly>
              </div>
              <div class="form-group col-md-2 col-4">
                <label for="qty">
                  <span class="d-none d-sm-inline-block d-lg-none">Excise($/L)</span>
                  <span class="d-sm-none d-lg-inline-block">Excise ($/L)</span>
                </label>
                <input class="form-control" type="text" id="excise" name="excise" [ngModel]="_order.excise" readonly>
              </div>
              <!-- <div class="form-group col-md-2 col-4">
                <label for="qty">
                  <span class="d-none d-sm-inline-block d-lg-none">Buy ($/L)</span>
                  <span class="d-sm-none d-lg-inline-block">Buy Price ($/L)</span>
                </label>
                <input class="form-control" type="text" id="buyPrice" name="buyPrice" [ngModel]="_order.buyPrice" readonly>
              </div> -->
            </div>
            <div class="row">
              <div class="form-group col-md-3 col-6">
                <label for="qty">
                  <span class="d-none d-sm-inline-block d-lg-none">Trans.</span>
                  <span class="d-sm-none d-lg-inline-block">Transport</span> Cost ($/L)</label>
                <input class="form-control" type="number" id="transCost" name="transCost" [(ngModel)]="_order.transportCosts">
              </div>
              <div class="form-group col-md-3 col-6">
                <label for="qty">Total Cost</label>
                <input class="form-control text-right" type="text" id="totalCost" name="totalCost" [ngModel]="totalCost() | currency:'AUD':'$':'4.0'"
                  readonly>
              </div>
              <!-- <div class="form-group col-md-3 col-6">
                <label for="qty">Total Income</label>
                <input class="form-control text-right" type="text" id="totalIncome" name="totalIncome" [ngModel]="totalIncome() | currency:'AUD':'$':'4.0'"
                  readonly>
              </div> -->
              <div class="form-group col-md-2 col-6">
                <label for="qty">Profit Margin</label>
                <input class="form-control text-right" type="text" id="profitMargin" name="profitMargin" [ngClass]="{'warning': profitMargin() <= 0}"
                  [ngModel]="profitMargin() | percent" readonly>
              </div>
            </div>


           
            <div class="row">
              <!-- DATES -->
              <div class="form-group col-md-6">
                <label for="pickup">Pickup / Start Date</label>
                <div class="input-group">
                  <p-calendar [(ngModel)]="_order.pickupDate" name="pickup" [required]="true" dateFormat="dd/mm/yy"  [showIcon]="true" icon="far fa-calendar" [selectOtherMonths]="true"></p-calendar>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="delivery">Delivery / End Date</label>
                <div class="input-group">
                  <p-calendar [(ngModel)]="_order.deliveryDate" name="delivery"  [required]="true" dateFormat="dd/mm/yy" [showIcon]="true" icon="far fa-calendar" [selectOtherMonths]="true"></p-calendar>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!_newOrder">
              <!-- TRANSPORT ORDER -->
              <div class="form-group col-md-4" *ngIf="!_order.transportOrder">
                <button class="btn btn-primary btn-raised" (click)="createTransportOrder(popupTransportOrder)" type="button" [disabled]="_order.exGate">
                  Create Transport Order
                  <i class="far fa-shipping-fast"></i>
                </button>
              </div>
              <div class="col-md-6" *ngIf="!_order.transportOrder">
                <span class="d-inline-block">
                  <i class="fal fa-minus"></i> or
                  <i class="fal fa-minus"></i>
                </span>
                <div class="form-check mb-2 d-inline-block">
                  <span class="switch switch-sm">
                    <input type="checkbox" class="switch" id="chkExGate" name="exGate" [(ngModel)]="_order.exGate">
                    <label for="chkExGate">Ex-Gate</label>
                  </span>
                </div>
              </div>
              <div class="form-group col-md-6" *ngIf="_order.transportOrder">
                <label for="transport">Transport Company</label>
                <input class="form-control" name="transport" [value]="_order.transportOrder.transportCompanyName" readonly>
              </div>
              <div class="form-group col-md-3" *ngIf="_order.transportOrder">
                <label for="transInvoice">Transport Invoice #</label>
                <input type="text" class="form-control" id="transInvoice" name="transInvoice" [(ngModel)]="_order.transportInvoiceNumber">
              </div>
              <div class="form-group col-md-3" *ngIf="_order.transportOrder" style="margin-top: 18px;">
                <button class="btn btn-primary btn-raised" (click)="showTransportOrder(popupTransportOrder)" type="button">
                  Transport Order
                  <i class="far fa-shipping-fast"></i>
                </button>
              </div>
            </div>
          </div>

          <!-- DOCUMENTS -->
          <div class="col-12 col-md-5">
            <div class="border p-2" *ngIf="!_newOrder">
              <app-order-documents [order]="_order"></app-order-documents>
            </div>
            <div class="form-group">
              <label for="comments">Notes / Comments</label>
              <textarea class="form-control" name="comments" [(ngModel)]="_order.comments" rows="6"></textarea>
            </div>
            <div *ngIf="_order.isCancelled" class="mt-4 p-1">
              <b>Reason Cancelled</b>
              <p>{{_order.cancelledReason}}</p>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="row mt-3 text-danger" *ngIf="invalidProfitMargin()">
        <div class="col">
          <i class="fas fa-exclamation-circle fa-2x"></i> WARNING : Please check costs as profit margin is outside normal range!
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <button type="submit" class="btn btn-primary" *ngIf="!_order.isCancelled" [disabled]="!orderForm.form.valid" (click)="save()">Save Order</button>
          <button type="button" class="btn btn-default ms-2" (click)="cancel()">
            <span *ngIf="_newOrder">Cancel</span>
            <span *ngIf="!_newOrder">Back to List</span>
          </button>
          <span class="d-none d-sm-inline-block mx-2"> | </span>
          <div class="d-sm-none">
            <hr> </div>
          <button class="btn btn-primary" *ngIf="!_newOrder" (click)="downloadOrder()">View Order
            <i class="far fa-download"></i>
          </button>
          <button class="ms-2 btn btn-success" *ngIf="_order.transportOrder || _order.exGate" (click)="sendToSupplier()">Send to Supplier
            <i class="far fa-envelope"></i>
          </button>
        </div>
        <div class="col-12 col-md-6">
          <span *ngIf="_order.dateSent">Sent to Supplier on : {{_order.dateSent | date:'dd/MM/yyyy h:mm aa':'+2000' }}</span> <!-- timezones causing issue, seems to ignore the GMT+10.  Adding +2000 fixes for display-->
          <button type="submit" class="btn btn-outline-primary float-right ms-2" *ngIf="!(_order.isCancelled || _order.transportOrder?.isDelivered) && _order.sentToSupplier"
          (click)="showCancelPopup(popupCancel)">Cancel Order</button>
        </div>
      </div>

    </form>
  </div>
</div>

<ng-template #popupTransportOrder let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Transport Order</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <app-transport-order-edit [order]="_order.transportOrder" [isInModal]="true" (saveOrder)="onSaveTransportOrder($event)"
          (cancelEdit)="onCancelEdit()"></app-transport-order-edit>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #popupCancel let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Cancel Order</h4>
  </div>
  <div class="modal-body">
    <p>Are you sure you wish to cancel this order? If so, enter the reason and click Yes below</p>
    <textarea rows="5" [(ngModel)]="cancelReason" name="reason" id="reason" required class="form-control mb-2"></textarea>
    <button type="submit" class="btn btn-primary" [disabled]="!cancelReason" (click)="yesCancel()">Yes - Cancel Order</button>
    <button type="submit" class="btn btn-outline-default" (click)="noCancel()">No</button>
  </div>
</ng-template>