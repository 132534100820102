import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  collapsed = true;
  loggedIn = false;

  constructor(private auth: AuthService) {
  }

  ngOnInit() {
    this.auth.loggedIn.subscribe(res => {
      this.loggedIn = res;
    });
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }
  collapse() {
    this.collapsed = true;
  }

}
