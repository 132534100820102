<div class="row equal-height">
  <div class="col-12 p-2 pb-0">
    <div class="title">Overview</div>
  </div>
  <div class="col-4">
    <div class="card border-0">
      <div class="card-header">Weekly Quantities</div>
      <div class="card-body">
        <ngx-loading [show]="!weeklyQuantities"></ngx-loading>
        <canvas baseChart [datasets]="weeklyQuantities" [labels]="weeklyLabels" [type]="'bar'"
          [options]="barQtyOptions" *ngIf="weeklyQuantities"></canvas>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="card border-0">
      <div class="card-header">Todays Deliveries</div>
      <div class="card-body">
        <ngx-loading [show]="!todaysDeliveries"></ngx-loading>
        <table class="table table-sm">
          <tbody>
            <tr *ngFor="let item of todaysDeliveries">
              <td>{{item.description}}</td>
              <td>{{item.quantity | number}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-4" *ngIf="counts">
    <div class="row">
      <div class="card col text-white bg-secondary border-0 pointer" (click)="gotoOrders(5)">
        <div class="card-body">
          <table class="w-100 p-0">
            <tr>
              <td>
                <i class="fas fa-usd-circle fa-2x"></i>
              </td>
              <td class="text-right">
                <h2>{{counts.toBeInvoiced}}</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">Ready to be invoiced</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="card col text-white bg-danger border-0 pointer" (click)="gotoOrders(6)">
        <div class="card-body">
          <table class="w-100 p-0">
            <tr>
              <td>
                <i class="far fa-file-exclamation fa-2x"></i>
              </td>
              <td class="text-right">
                <h2>{{counts.missingDocuments}}</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">Missing documents</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="card col text-white bg-warning border-0 pointer" (click)="gotoOrders(4)">
        <div class="card-body">
          <table class="w-100 p-0">
            <tr>
              <td>
                <i class="fas fa-truck-moving fa-2x"></i>
              </td>
              <td class="text-right">
                <h2>{{counts.pendingDelivery}}</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">Awaiting delivery confirmation</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="card col text-white bg-success border-0 pointer" (click)="gotoOrders(2)">
        <div class="card-body">
          <table class="w-100 p-0 text-center">
            <tr>
              <td colspan="3" class="text-center">
                <h5>Weekly Orders</h5>
              </td>
            </tr>
            <tr>
              <td>
                <small>Current</small>
              </td>
              <td>
                <small>Next</small>
              </td>
              <td>
                <small>Average</small>
              </td>
            </tr>
            <tr>
              <td style="width: 33%">
                <h2>{{counts.currentWeek}}</h2>
              </td>
              <td style="width: 33%">
                <h2>{{counts.nextWeek}}</h2>
              </td>
              <td style="width: 33%">
                <h2>{{counts.weeklyAverage}}</h2>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row equal-height">
  <div class="col-12 p-2 pb-0">
    <div class="title">Current Week</div>
  </div>
  <div class="col-xs-12 col-md-6 col-lg-4">
    <div class="card border-0">
      <div class="card-header">Transports</div>
      <div class="card-body">
        <ngx-loading [show]="!txWeekData"></ngx-loading>
        <canvas baseChart [data]="txWeekData" [labels]="txWeekLabels" [type]="'doughnut'" *ngIf="txWeekData" 
          [options]="doughOptions"></canvas>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-6 col-lg-4">
    <div class="card border-0">
      <div class="card-header">Products</div>
      <div class="card-body">
        <ngx-loading [show]="!prodWeekData"></ngx-loading>
        <canvas baseChart [datasets]="prodWeekDataSets" [labels]="prodWeekLabels" [type]="'bar'" 
          *ngIf="prodWeekDataSets" [options]="barProductOptions"></canvas>
        <!-- <canvas baseChart [data]="prodWeekData" [labels]="prodWeekLabels" [chartType]="'doughnut'" *ngIf="prodWeekData"></canvas> -->
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-6 col-lg-4">
    <div class="card border-0">
      <div class="card-header">Top Customers</div>
      <div class="card-body">
        <ngx-loading [show]="!topCustomersWeek"></ngx-loading>
        <table class="table table-sm">
          <tbody>
            <tr *ngFor="let item of topCustomersWeek">
              <td>{{item.description}}</td>
              <td>{{item.quantity | number}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-12 p-2 pb-0">
    <div class="title">Past 90 Days</div>
  </div>
  <div class="col-xs-12 col-md-6 col-lg-4">
    <div class="card border-0">
      <div class="card-header">Transports</div>
      <div class="card-body">
        <ngx-loading [show]="!tx90Data"></ngx-loading>
        <canvas baseChart [data]="tx90Data" [labels]="tx90Labels" [type]="'doughnut'" *ngIf="tx90Data"></canvas>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-6 col-lg-4">
    <div class="card border-0">
      <div class="card-header">Products</div>
      <div class="card-body">
        <ngx-loading [show]="!prod90Data"></ngx-loading>
        <canvas baseChart [datasets]="prod90DataSets" [labels]="prod90Labels" [type]="'bar'" *ngIf="prod90DataSets" 
          [options]="barProductOptions"></canvas>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-md-6 col-lg-4">
    <div class="card border-0">
      <div class="card-header">Top Customers</div>
      <div class="card-body">
        <ngx-loading [show]="!topCustomers90"></ngx-loading>
        <table class="table table-sm" *ngIf="topCustomers90">
          <tbody>
            <tr *ngFor="let item of topCustomers90">
              <td>{{item.description}}</td>
              <td>{{item.quantity | number}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>