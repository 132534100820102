import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TransportOrderItem, TransportOrder, TransportOrderService, Delivery } from '../../../services/transport-order.service';
import { TransportsService, TransportCompany } from '../../../services/transports.service';
import { OrdersService, OrderListItem } from '../../../services/orders.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersService, Customer } from '../../../services/customers.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-transport-order-edit',
  templateUrl: './transport-order-edit.component.html',
  styleUrls: ['./transport-order-edit.component.css']
})
export class TransportOrderEditComponent implements OnInit {
  @Output() saveOrder: EventEmitter<TransportOrder> = new EventEmitter<TransportOrder>();
  @Output() cancelEdit: EventEmitter<void> = new EventEmitter();
  @Input() isInModal: boolean;
  _order: TransportOrder;
  @Input() set order(value) {
    this._order = value;
  }

  loading = false;                        // show loading panel
  noTransportOrderYet: OrderListItem[];   // list of supplier order not yet assigned to a transport order

  customers: Customer[];                  // List of customers that could be delivered to
  _selectedDelivery: Delivery;            // Currently selected delivery, documents will be displayed for this delivery

  constructor(private modalService: NgbModal,
    private customerService: CustomersService,
    private transportService: TransportsService,
    private orderService: OrdersService,
    private tportOrderService: TransportOrderService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.loading = true;
    if (!this._order) {
      this.route.params.subscribe(params => {
        const id = params['id'];
        if (id === '0') {
          this._order = new TransportOrder();
          this.orderLoaded();
        } else {
          this.tportOrderService.getOrder(id).subscribe(o => {
            this._order = o;
            this.orderLoaded();
          });
        }
      });
    } else {
      this.orderLoaded();
    }

    this.orderService.getOrders().subscribe(res => {
      this.noTransportOrderYet = res.filter(o => !o.transportCode && !o.isCancelled && !o.isDelivered && !o.exGate);
    }, err => {
      alert(err.error);
    });

  }

  onTransportSelected(transport: TransportCompany) {
    this._order.transportCompanyName = transport.companyName;
  }

  // need to do this after loading the order in case this is an old order and the transport company has been deactivated
  orderLoaded() {
    let allCustomers: number[] = [];
    this._order.items.forEach(x => { allCustomers = allCustomers.concat(x.customerIDs); });
    this.customerService.allCustomers$.subscribe(res => {
      this.customers = res.filter(o => allCustomers.includes(o.customerID));
    });
    this.loading = false;
  }

  openChooseOrders(content) {
    this.modalService.open(content, { centered: true, size: 'lg' }).result.then((items) => {
      this.noTransportOrderYet.filter(o => o.selected).forEach(o => {
        if (!this._order.items.find(x => x.supplierOrderID === o.orderID)) {
          const newItem = new TransportOrderItem();
          newItem.supplierOrderID = o.orderID;
          newItem.productCode = o.productCode;
          newItem.productID = o.productID;
          newItem.quantity = o.totalQuantity;
          newItem.supplierCode = o.supplierCode;
          newItem.supplierID = o.supplierID;
          newItem.supplierOrderID = o.orderID;
          this._order.items.push(newItem);
        }
      });

      this.noTransportOrderYet = this.noTransportOrderYet.filter(o => !o.selected);
    }, (reason) => {
      // cancelled
    });
  }


  save() {
    this.loading = true;
    if(this._order.deliveries) this._order.deliveries.forEach(del => del.deliveryDate.setHours(12));    // fix date only values so they are correct for timezone
    if (!this._order.transportOrderID) {
      this.tportOrderService.createOrder(this._order).subscribe(res => {
        this.loading = false;
        if (this.isInModal) {
          this.saveOrder.emit(res);
        } else {
          this.router.navigate(['/transportorders/' + res.transportOrderID.toString()]);  // reopen in edit mode
        }
      }, err => {
        alert(err.error);
        this.loading = false;
      });
    } else {
      this.tportOrderService.saveOrder(this._order).subscribe(res => {
        this.loading = false;
        this._selectedDelivery = null;
        if (this.isInModal) { this.saveOrder.emit(res); }
      }, err => {
        alert(err.error);
        this.loading = false;
      });
    }
  }

  cancel() {
    if (this.isInModal) {
      this.cancelEdit.emit();
    } else {
      this.router.navigate(['/transportorders']);
    }
  }

  removeItem(index: number) {
    if (!window.confirm('Are you sure you wish to remove this order?')) { return; }
    this._order.items.splice(index, 1);
  }

  downloadTransportOrder() {
    this.loading = true;
    this.tportOrderService.saveOrder(this._order).subscribe(res => {
      this.tportOrderService.generateOrder(this._order).subscribe(url => {
        this.loading = false;
        window.open(url, '_blank');
      }, err => {
        alert('An error occurred attepting to generate order document');
        this.loading = false;
      });
    }, err => {
      this.loading = false;
      alert('An error occurred saving transport operator');
    });
  }

  sendToTransport() {
    const txp = this.transportService.getTransport(this._order.transportID);
    if (!txp || (!window.confirm('Order will be emailed to ' + txp.emailAddress + '.  Are you sure?'))) {
      return;
    }

    this.loading = true;
    this.tportOrderService.saveOrder(this._order).subscribe(res => {
      this.tportOrderService.sendOrder(this._order).subscribe(order => {
        this.loading = false;
        this._order = order;
      }, err => {
        alert('An error occurred sending email to transport operator');
        this.loading = false;
      });
    }, err => {
      this.loading = false;
      alert('An error occurred saving transport operator');
    });
  }

  addDelivery() {
    if (!this._order.deliveries) { this._order.deliveries = []; }
    const del = new Delivery();
    del.deliveryDate = new Date();
    this._order.deliveries.push(del);
  }
  removeDelivery(idx: number) {
    const delItem = this._order.deliveries[idx];
    if (delItem.transportOrderDeliveryID) {
      if (!confirm('Are you sure you wish to delete this delivery?')) {
        return; // only bother checking if delivery was saved to DB
      }
    }
    if (delItem === this._selectedDelivery) { this._selectedDelivery = null; }
    this._order.deliveries.splice(idx, 1);
  }
  selectDelivery(del: Delivery) {
    this._selectedDelivery = del;
  }
  isSelected(del) {
    return this._selectedDelivery === del;
  }
}
