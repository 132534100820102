import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DocumentsService {
  apiEndpoint = window.location.origin + '/api/documents/';

  constructor(private http: HttpClient) { }

  getOrderDocuments(id: string) {
    return this.http.get<AzureDocument[]>(this.apiEndpoint + 'order/' + id);
  }

  getDeliveryDocuments(id: string) {
    return this.http.get<AzureDocument[]>(this.apiEndpoint + 'delivery/' + id);
  }

  setIgnoreMissing(documentID: number, type: number) {
    return this.http.post<AzureDocument>(this.apiEndpoint + (type === 1 ? 'order/' : 'delivery/') + documentID.toString() + "/set-ignore", {});
  }

  /// type = 1 -- order, type = 2 -- delivery
  uploadDocument(documentID: number, data: File, type: number) {
      const formData: FormData = new FormData();
      formData.append('file', data, data.name);
      return this.http.post<AzureDocument>(this.apiEndpoint + (type === 1 ? 'order/' : 'delivery/') + documentID.toString(), formData);
  }

}

// can't call it Document as it clashes with a node type
export class AzureDocument {
    public documentID: number;
    public sequenceNo: number;
    public documentType: string;
    public downloadURL: string;
    public dateUploadedUTC: Date;
    public ignoreMissing: boolean;
}

