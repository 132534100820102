<div class="text-center">
  <b>Documents for deliver {{_delivery.deliveryDate | date:'dd/MM/yyyy' }}</b>
</div>
<table class="documentsTable">
  <ngx-loading [show]="loading"></ngx-loading>
  <tbody *ngIf="!showSaveMessage">
    <tr *ngFor="let doc of documents; let idx = index">
      <td>
        <i class="fas fa-times-circle fa-fw text-muted" *ngIf="!doc.downloadURL && !doc.ignoreMissing"></i>
        <i class="fas fa-file-times fa-fw text-warning" *ngIf="doc.ignoreMissing" [ngbTooltip]="'Missing document ignored'"></i>
        <i class="fas fa-check-circle fa-fw text-success" *ngIf="doc.downloadURL"></i>
      </td>
      <td>{{doc.documentType}}</td>
      <td class="text-center">
        <a [href]="doc.downloadURL" target="_blank" *ngIf="doc.downloadURL" class="custom-file-upload">View</a>
      </td>
      <td class="text-center">
        <label for="file-upload-{{doc.documentID}}" class="custom-file-upload">
          <span *ngIf="!doc.downloadURL">Upload</span>
          <span *ngIf="doc.downloadURL">Replace</span>
        </label>
        <input id="file-upload-{{doc.documentID}}" name="file-upload-{{doc.documentID}}" type="file"
          (change)="handleFileInput($any($event.target).files, doc.documentID)">
      </td>
      <td>
        <button *ngIf="!doc.downloadURL && !doc.ignoreMissing" class="btn btn-link btn-sm" (click)="setIgnore(doc.documentID)">Ignore Missing</button>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="showSaveMessage">
    <tr>
      <td>
        <p>Please save Transport Order before uploading documents</p>
      </td>
    </tr>
  </tbody>

</table>