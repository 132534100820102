import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TransportCompany } from '../../../services/transports.service';

@Component({
  selector: 'app-transport-edit',
  templateUrl: './transport-edit.component.html',
  styleUrls: ['./transport-edit.component.css']
})
export class TransportEditComponent implements OnInit {
  @Output() saveTransport: EventEmitter<TransportCompany> = new EventEmitter<TransportCompany>();
  @Output() cancelEdit: EventEmitter<void> = new EventEmitter();

  _transport: TransportCompany;
  @Input() set transport(value) {
    this._transport = value;
  }

  constructor() { }

  ngOnInit() {
  }

  save() {
    this.saveTransport.emit(this._transport);
  }

  cancel() {
    this.cancelEdit.emit();
  }



}
