<div class="card" *ngIf="_order">

  <div class="card-header">{{!_order.transportOrderID ? 'New Order' : 'Edit Transport Order'}}</div>
  <div class="card-body">
    <ngx-loading [show]="loading"></ngx-loading>
    <form #transportForm="ngForm">
      <div class="row">
        <div class="form-group col-md-6">
          <label for="trans">Transport Operator</label>
          <app-select-transport [(transportID)]="_order.transportID" appAutoFocus (selectedTransport)="onTransportSelected($event)"></app-select-transport>
        </div>
        <div class="form-group col-md-6 text-right" *ngIf="_order.transportOrderID">

        </div>
      </div>


      <div class="card border-1 p-2 mb-2 noshadow" style="background-color: #f7f7f7;">
        <table class="table table-sm table-bordered">
          <thead class="thead-light">
            <tr>
              <th>CWFO PO</th>
              <th>Supplier</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let toi of _order.items; let idx = index">
              <td>
                {{toi.supplierOrderID}}
              </td>
              <td>
                {{toi.supplierCode}}
              </td>
              <td>
                {{toi.productCode}}
              </td>
              <td>
                {{toi.quantity}}
              </td>
              <td>
                <button class="btn btn-sm btn-default" *ngIf="!_order.sentToTransport" (click)="removeItem(idx)">
                  <i class="far fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-2">
            <button class="btn btn-sm btn-primary" *ngIf="!_order.sentToTransport" (click)="openChooseOrders(popupContent)">Add Order</button>
          </div>
        </div>
      </div>

      <!-- Instructions -->
      <div class="row">
        <div class="form-group" [ngClass]="{'col-md-6': !isInModal, 'col-md-9': isInModal}">
          <label for="si">Special Instructions</label>
          <textarea class="form-control" name="si" id="si" [(ngModel)]="_order.specialInstructions" rows="4"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-md-3">
          <div class="form-check mb-2">
            <span class="switch switch-sm">
              <input type="checkbox" class="switch" id="chkMulti" name="isMultiDelivery" [(ngModel)]="_order.isMultipleDelivery">
              <label for="chkMulti">Multi Delivery</label>
            </span>
          </div>
        </div>
        <div class="col-sm-6 col-md-3" *ngIf="_order.transportOrderID">
          <div class="form-check mb-2">
            <span class="switch switch-sm">
              <input type="checkbox" class="switch" id="chkDelivered" name="isDelivered" [(ngModel)]="_order.isDelivered" [disabled]="!_order.deliveries || _order.deliveries.length == 0">
              <label for="chkDelivered">Delivered</label>
            </span>
          </div>
          <small *ngIf="!_order.deliveries || _order.deliveries.length == 0">At least one delivery must be added to mark as Delivered</small>
        </div>
      </div>

      <!-- Deliveries : only showing if editing the order -->
      <div class="row" *ngIf="_order.transportOrderID">
        <div class="col">
          <hr>
        </div>
        <div class="w-100"></div>
        <div [ngClass]="{'col-lg-6': !isInModal, 'col-lg-12': isInModal}">
          <h4>Deliveries</h4>
          <table class="table table-sm table-bordered">
            <thead class="thead-light">
              <tr>
                <th style="width: 30px;">Docs</th>
                <th>Customer</th>
                <th style="width: 145px">Delivery Date</th>
                <th style="width: 90px">Quantity</th>
                <th style="width: 10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let del of _order.deliveries; let idx = index">
                <td class="text-center" style="font-size: 1.3em" [ngClass]="{'bg-success': del.documentsComplete, 'bg-danger': !del.documentsComplete}">
                  <i class="far" [ngClass]="{'fa-square': !isSelected(del), 'fa-check-square': isSelected(del) }" (click)="selectDelivery(del)" title="Show Documents"></i>
                </td>
                <td>
                  <select class="form-control" name="del-{{idx}}" id="del" [(ngModel)]="del.customerID" required>
                    <option *ngFor="let c of customers" value="{{c.customerID}}">{{c.companyName}}</option>
                  </select>
                </td>
                <td>
                  <div class="input-group">
                    <p-calendar [(ngModel)]="del.deliveryDate" name="delivery"  [required]="true" dateFormat="dd/mm/yy" [showIcon]="true" icon="far fa-calendar" [selectOtherMonths]="true"></p-calendar>
                  </div>
                </td>
                <td>
                  <input type="number" class="form-control" id="delQty" name="delQty-{{idx}}" [(ngModel)]="del.quantity" required>
                </td>
                <td>
                  <button class="btn btn-sm btn-default" (click)="removeDelivery(idx)">
                    <i class="far fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row" *ngIf="_order.isMultipleDelivery || !_order.deliveries || _order.deliveries.length === 0">
            <div class="col-md-2">
              <button class="btn btn-sm btn-primary" (click)="addDelivery()">Add Delivery</button>
            </div>
          </div>
        </div>
        <div [ngClass]="{'col-lg-6': !isInModal, 'col-lg-12': isInModal}">
          <div class="border p-2" *ngIf="_selectedDelivery">
            <app-delivery-documents [delivery]="_selectedDelivery"></app-delivery-documents>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col">
          <hr>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-9">
          <button type="submit" class="btn btn-primary" [disabled]="!transportForm.form.valid" (click)="save()">Save Order</button>
          <button type="button" class="btn btn-default ms-2" (click)="cancel()">
            <span *ngIf="_order.transportOrderID">Back</span>
            <span *ngIf="!_order.transportOrderID">Cancel</span>
          </button>
          <span class="d-none d-sm-inline-block mx-2"> | </span>
          <div class="d-sm-none"> <hr> </div>
          <button class="btn btn-primary" *ngIf="_order.transportOrderID" (click)="downloadTransportOrder()">View Order <i class="far fa-download"></i>
          </button>
          <button class="ms-2 btn btn-success" *ngIf="_order.transportOrderID" (click)="sendToTransport()">Send to Transport <i class="far fa-envelope"></i>
          </button>
        </div>
        <div class="col-12 col-md-3">
          <p *ngIf="_order.sentToTransport">Sent to Transport on : {{_order.dateSent | date:'dd/MM/yyyy hh:mm aa':'+2000'}}</p><!-- timezones causing issue, seems to ignore the GMT+10.  Adding +2000 fixes for display-->
        </div>
      </div>

    </form>
  </div>
</div>


<ng-template #popupContent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Choose Order(s)</h4>
  </div>
  <div class="modal-body">
    <table class="table table-sm table-bordered">
      <thead class="thead-light">
        <tr>
          <th style="width: 40px"></th>
          <th style="width: 70px">CWFO #</th>
          <th style="width: 80px">Del. Date</th>
          <th style="width: 90px">Supplier</th>
          <th style="width: 90px">Product</th>
          <th style="width: 80px">Total Qty</th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngFor="let o of noTransportOrderYet">
          <td>
            <input type="checkbox" [(ngModel)]="o.selected">
          </td>
          <td>{{o.orderID}}</td>
          <td>{{o.deliveryDate | date:'dd/MM/yy'}}</td>
          <td>{{o.supplierCode}}</td>
          <td>{{o.productCode}}</td>
          <td>{{o.totalQuantity}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c()">Add Selected</button>
    <button type="button" class="btn btn-outline-dark" (click)="d('Cancel')">Cancel</button>
  </div>
</ng-template>