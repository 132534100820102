<p-dropdown [options]="(products$ | async)" [(ngModel)]="selected" dataKey="customerID" appendTo="body" [autoDisplayFirst]="false" [scrollHeight]="'300px'" [style]="{width: '100%', 'border-radius': '0', 'border-color': '#ced4da', 'font-size': '0.8rem' }" (onChange)="changed()"
    [emptyMessage]="'No suppliers for the selected customerxxx'">
  <ng-template pTemplate="selectedItem">
      <div *ngIf="selected">
          <div>{{selected.code}} - {{ selected.description }}</div>
      </div>
  </ng-template>
  <ng-template let-product pTemplate="item">
      <div>
        <div>{{product.code}} - {{ product.description }}</div>
      </div>
  </ng-template>
</p-dropdown>

<!-- <select class="form-control" id="product" name="product" [(ngModel)]="productID" required (ngModelChange)="changed()" *ngIf="(products$ | async) as data">
    <option *ngFor="let p of data" value="{{p.productID}}">{{p.code}} - {{p.description}}</option>
  </select>
 -->
