import { Component, Input, OnInit } from '@angular/core';
import { ProductSupplierService, ProductSupplier } from '../../../../services/product-supplier.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-product-suppliers',
  templateUrl: './product-suppliers.component.html',
  styleUrls: ['./product-suppliers.component.css']
})
export class ProductSuppliersComponent implements OnInit {
  @Input() productID: number;
  items$: Observable<ProductSupplier[]>;
  loading$: Observable<boolean>;

  newSupplierID: number;
  newBuyPrice: number;

  constructor(private service: ProductSupplierService) {
    this.loading$ = this.service.loading$;
    this.items$ = this.service.productSuppliers$.pipe(
      map(res => res.filter(x => x.productID === this.productID)),
      map(res => res.sort((a, b) => a.supplierName.localeCompare(b.supplierName))),
      tap(() => { this.newSupplierID = 0; this.newBuyPrice = 0; })
    );
  }

  ngOnInit(): void {
  }

  addSupplier() {
    if (!this.newSupplierID) return;
    this.service.addProductSupplier(this.productID, this.newSupplierID, this.newBuyPrice);
  }

  confirmRemove(item: ProductSupplier) {
    if (!confirm(`Are you sure you want to remove ${item.supplierName} from this product?`)) return;
    this.service.removeItem(item.supplierProductID);
  }

  savePrice(item: ProductSupplier) {
    this.service.updateProductSupplier(item.supplierProductID, item.buyPrice);
  }
}
