<table class="documentsTable">
  <ngx-loading [show]="loading"></ngx-loading>
  <tr *ngFor="let doc of documents; let idx = index">
    <td>
      <i class="fas fa-times-circle fa-fw text-muted" *ngIf="!doc.downloadURL && !doc.ignoreMissing"></i>
      <i class="fas fa-file-times fa-fw text-warning" *ngIf="doc.ignoreMissing"></i>
      <i class="fas fa-check-circle fa-fw text-success" *ngIf="doc.downloadURL"></i>
    </td>
    <td>{{doc.documentType}}</td>
    <td class="text-center">
      <a [href]="doc.downloadURL" target="_blank" *ngIf="doc.downloadURL" class="custom-file-upload">View</a>
    </td>
    <td class="text-center">
      <div *ngIf="doc.documentType != 'CWFO PO'">
        <label for="file-upload-{{idx}}" class="custom-file-upload">
          <span *ngIf="!doc.downloadURL">Upload</span>
          <span *ngIf="doc.downloadURL">Replace</span>
        </label>
        <input id="file-upload-{{idx}}" name="file-upload-{{idx}}" type="file"
          (change)="handleFileInput($any($event.target).files, doc.documentID)" />
      </div>
      <div *ngIf="doc.documentType == 'CWFO PO'">
        <label *ngIf="!doc.downloadURL" (click)="generateCWFOPO()" class="custom-file-upload">Generate</label>
        <label *ngIf="doc.downloadURL" (click)="generateCWFOPO()" class="custom-file-upload">Regenerate</label>
      </div>
    </td>
    <td>
      <button *ngIf="!doc.downloadURL && !doc.ignoreMissing" class="btn btn-link btn-sm" (click)="setIgnore(doc.documentID)">Ignore Missing</button>
    </td>
  </tr>
</table>