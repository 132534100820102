import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Customer } from '../../../services/customers.service';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css']
})
export class CustomerEditComponent implements OnInit {
  @Output() saveCustomer: EventEmitter<Customer> = new EventEmitter<Customer>();
  @Output() cancelEdit: EventEmitter<void> = new EventEmitter();

  _customer: Customer;
  @Input() set customer(value) {
    this._customer = value;
  }

  constructor() { }

  ngOnInit() {
  }

  save() {
    this.saveCustomer.emit(this._customer);
  }

  cancel() {
    this.cancelEdit.emit();
  }

}
