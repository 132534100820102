import { Component, OnInit, Input } from '@angular/core';
import { DocumentsService, AzureDocument } from '../../../services/documents.service';
import { OrdersService, Order } from '../../../services/orders.service';

@Component({
  selector: 'app-order-documents',
  templateUrl: './order-documents.component.html',
  styleUrls: ['./order-documents.component.css']
})
export class OrderDocumentsComponent implements OnInit {
  _order: Order;
  @Input() set order(value: Order) {
    this._order = value;
    if(this._order && this._order.orderID) this.loadDocuments();
  }
  documents: AzureDocument[];
  loading = false;
  constructor(private docService: DocumentsService, private orderService: OrdersService) { }

  ngOnInit() {
    this.loading = true;
  }
  private loadDocuments() {
    this.docService.getOrderDocuments(this._order.orderID.toString()).subscribe(res => {
      this.documents = res;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  handleFileInput(files: FileList, documentID: number) { // called each time file input changes
    if (files.item(0) === undefined) {
      return;
    }
    this.loading = true;
    // default to the person's container unless the container property has been explicitly set
    this.docService.uploadDocument(documentID, files.item(0), 1).subscribe(res => {
      const updateDoc = this.documents.find(o => o.documentID === res.documentID);
      updateDoc.dateUploadedUTC = res.dateUploadedUTC;
      updateDoc.downloadURL = res.downloadURL;
      this.loading = false;
    }, err => {
      console.log(err);
      alert(err.error);
      this.loading = false;
    });
  }

  generateCWFOPO() {
    this.loading = true;
    this.orderService.generateOrder(this._order).subscribe(res => {
      this.loadDocuments();
    }, err => {
      this.loading = false;
    });
  }

  setIgnore(documentID: number) {
    this.loading = true;
    this.docService.setIgnoreMissing(documentID, 1).subscribe(() => {
      const updateDoc = this.documents.find(o => o.documentID === documentID);
      updateDoc.ignoreMissing = true;
      this.loading = false;
    }, err => {
      console.log(err);
      this.loading = false;
    });
  }

}
