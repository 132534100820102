import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ProductSupplier, ProductSupplierService } from '../../../services/product-supplier.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-productsupplier',
  templateUrl: './select-productsupplier.component.html',
  styleUrls: ['./select-productsupplier.component.css']
})
export class SelectProductsupplierComponent implements OnInit {
  options$: Observable<ProductSupplier[]>;
  @Input() selected: ProductSupplier;

  @Output() selectedChange = new EventEmitter<ProductSupplier>();

  constructor(private service: ProductSupplierService) { 
    this.options$ = this.service.productSuppliers$;
  }

  ngOnInit(): void {
  }

  onSelectChanged() {
    this.selectedChange.emit(this.selected);  
  }

}
