import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerProductSupplierService, CustomerProductSupplier } from '../../../../services/customer-product-supplier.service';
import { ProductSupplier } from '../../../../services/product-supplier.service';

@Component({
  selector: 'app-customer-supplier-products',
  templateUrl: './customer-supplier-products.component.html',
  styleUrls: ['./customer-supplier-products.component.css']
})
export class CustomerSupplierProductsComponent implements OnInit {
  @Input() customerID: number;
  items$: Observable<CustomerProductSupplier[]>;
  loading$: Observable<boolean>;

  selectedProdSupplier: ProductSupplier;
  newSellPrice: number;

  constructor(private service: CustomerProductSupplierService) {
    this.loading$ = this.service.loading$;
    this.items$ = this.service.productSuppliers$.pipe(
      map(res => res.filter(x => x.customerID === this.customerID)),
      map(res => res.sort((a, b) => a.supplierName.localeCompare(b.supplierName)))
    );
  }

  ngOnInit(): void {
  }

  addSupplier() {
    if (!this.selectedProdSupplier) return;
    this.service.addItem(this.customerID, this.selectedProdSupplier.supplierProductID, this.newSellPrice);
  }

  confirmRemove(item: CustomerProductSupplier) {
    if (!confirm(`Are you sure you want to remove ${item.supplierName} from this product?`)) return;
    this.service.removeItem(item.customerProductID);
  }

  savePrice(item: CustomerProductSupplier) {
    this.service.updateItem(item.customerProductID, item.sellPrice);
  }

}
