import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransportOrder } from './transport-order.service';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class OrdersService {
  apiEndpoint = window.location.origin + '/api/orders';
  currentSearch = '0';  // saves the quicksearch between pages

  constructor(private http: HttpClient) { }

  getOrders() {
    return this.http.get<OrderListItem[]>(this.apiEndpoint);
  }

  getToBeInvoiced() {
    return this.http.get<ToBeInvoicedListItem[]>(this.apiEndpoint + '/invoicelist').pipe(tap((items) => {
      items.forEach(item => {
        item.deliveryDate = moment(item.deliveryDate).toDate();
      })
    }));
  }

  getOrder(id: string) {
    return this.http.get<Order>(this.apiEndpoint + '/' + id).pipe(tap((item) => {
      if (item.dateSent) item.dateSent = new Date(item.dateSent);
      console.log(item.deliveryDate, moment(item.deliveryDate).toDate(), moment(item.deliveryDate).date);
      item.deliveryDate = moment(item.deliveryDate).toDate();
      item.pickupDate = moment(item.pickupDate).toDate();
    }));
  }

  createOrder(vm: Order) {
    return this.http.post<Order>(this.apiEndpoint, vm);
  }

  cancelOrder(vm: Order, reason: string) {
    return this.http.post<Order>(this.apiEndpoint + '/' + vm.orderID.toString() + '/cancel', { reason: reason });
  }
  deleteOrder(vm: Order) {
    return this.http.delete(this.apiEndpoint + '/' + vm.orderID.toString());
  }

  saveOrder(vm: Order) {
    return this.http.put<Order>(this.apiEndpoint + '/' + vm.orderID.toString(), vm);
  }

  generateOrder(vm: Order) {
    return this.http.post<string>(this.apiEndpoint + '/' + vm.orderID.toString() + '/generate', vm);
  }

  sendOrder(vm: Order) {
    return this.http.post<Order>(this.apiEndpoint + '/' + vm.orderID.toString() + '/send', vm);
  }

  uploadCustomerOrderDocument(vm: Order, customerOrderID: number, data: File) {
    const formData: FormData = new FormData();
    formData.append('file', data, data.name);
    const endpoint = this.apiEndpoint + '/' + vm.orderID.toString() + '/customerOrder/' + customerOrderID.toString();
    return this.http.post<string>(endpoint, formData);
  }

}


export class OrderListItem {
  public selected: boolean;   // selecting flag in list
  public orderID: number;
  public qbInvoiceNumbers: string;
  public supplierCode: string;
  public supplierID: number;
  public supplierInvoiceNumber: string;
  public productID: number;
  public productCode: string;
  public totalQuantity: number;
  public deliveredQuantity: number;
  public pickupDate: Date;
  public deliveryDate: Date;
  public sellPrice: number;
  public excise: number;
  public transportCode: number;
  public isDelivered: boolean;
  public documentsCompleted: boolean;
  public customersText: string;
  public isCancelled: boolean;
  public invoicingComplete: boolean;
  public exGate: boolean;
  public sentToSupplier: boolean;
  public transportInvoiceNumber: string;
}

export class ToBeInvoicedListItem {
  public orderID: number;
  public qbInvoiceNumber: string;
  public transportOrderID: number;
  public customerID: number;
  public customerName: string;
  public customerPO: string;
  public productID: number;
  public productCode: string;
  public deliveredQuantity: number;
  public deliveryDate: Date;
  public sellPrice: number;
  public excise: number;
}

export class Order {
  public orderID: number;
  public supplierID: number;
  public supplierInvoiceNumber: string;
  public productID: number;
  public totalQuantity: number;
  public pickupDate: Date;
  public deliveryDate: Date;
  public buyPrice: number;
  public transportCosts: number;
  public excise: number;
  public transportOrder: TransportOrder;
  public isDelivered: boolean;
  public customerOrders: OrderItem[] = [];
  public documents: Document[] = [];
  public isCancelled: boolean;
  public cancelledReason: string;
  public comments: string;
  public exGate: boolean;
  public sentToSupplier: boolean;
  public dateSent: Date;
  public transportInvoiceNumber: string;
}
export class OrderItem {
  public orderCustomerItemID: number;
  public customerID: number;
  public customerCode: string;
  public customerPONumber: string;
  public quantity: number;
  public sellPrice: number;
  public documentDownloadURL: string;
  public documentDateUploaded: Date;
  public qbInvoiceNumber: string;
}
export class Document {
  public documentType: string;
  public sequenceNo: number;
  public documentID: number;
  public downloadURL: string;
  public dateUploaded: Date;
}
