import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardService {
  apiEndpoint = window.location.origin + '/api/dashboard';


  constructor(private http: HttpClient) {
  }

  getWeeklyTotals() {
    return this.http.get<WeeklyTotal[]>(this.apiEndpoint + '/weeklytotals');
  }

  getCounts() {
    return this.http.get<CountData>(this.apiEndpoint + '/counts');
  }


  getTransportWeek() {
    return this.http.get<ItemTotals[]>(this.apiEndpoint + '/transportweek');
  }

  getTransport90() {
    return this.http.get<ItemTotals[]>(this.apiEndpoint + '/transport90');
  }

  getProductWeek() {
    return this.http.get<ItemTotals[]>(this.apiEndpoint + '/productweek');
  }

  getProduct90() {
    return this.http.get<ItemTotals[]>(this.apiEndpoint + '/product90');
  }

  getTopCustomerWeek() {
    return this.http.get<ItemTotals[]>(this.apiEndpoint + '/topcustomerweek');
  }

  getTopCustomer90() {
    return this.http.get<ItemTotals[]>(this.apiEndpoint + '/topcustomer90');
  }

  getTodaysDeliveries() {
    return this.http.get<ItemTotals[]>(this.apiEndpoint + '/todaysdeliveries');
  }
}


export class ItemTotals {
  public id: number;
  public description: string;
  public orders: number;
  public quantity: number;
}

export class WeeklyTotal {
  public weekStart: Date;
  public orders: Number;
  public quantity: Number;
  public profit: Number;
}

export class CountData {
  public toBeInvoiced: Number;
  public pendingDelivery: Number;
  public missingDocuments: Number;
  public currentWeek: Number;
  public nextWeek: Number;
  public weeklyAverage: Number;
}
