import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TransportsService {
  apiEndpoint = window.location.origin + '/api/transports';

  
  private _transports: TransportCompany[];
  allTransports$ = new ReplaySubject<TransportCompany[]>();

  constructor(private http: HttpClient) {
    this.reloadTransports();
   }

  reloadTransports() {
    this.http.get<TransportCompany[]>(this.apiEndpoint).subscribe({
      next: res => {
        this._transports = res;
        this.allTransports$.next(res);
      },
      error: err => {
        console.log('Error loading transports :', err);
      }
    });
  }

  getTransport(id: number) {
    return this._transports.find(x => x.transportID == id);
  }

  createTransport(vm: TransportCompany) {
    return this.http.post<TransportCompany>(this.apiEndpoint, vm);
  }

  saveTransport(vm: TransportCompany) {
    return this.http.put<TransportCompany>(this.apiEndpoint + '/' + vm.transportID.toString(), vm);
  }
}



export class TransportCompany {
  public transportID: number;
  public code: string;
  public companyName: string;
  public contactPerson: string;
  public emailAddress: string;
  public ccEmail: string;
  public address1: string;
  public suburb: string;
  public state: string;
  public postcode: string;
  public phone: string;
  public altPhone: string;
  public isActive: boolean;
}
