import { CustomerProductSupplier, CustomerProductSupplierService } from './../../../services/customer-product-supplier.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Supplier, SuppliersService } from '../../../services/suppliers.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-select-supplier',
  templateUrl: './select-supplier.component.html',
  styleUrls: ['./select-supplier.component.css']
})
export class SelectSupplierComponent implements OnInit {
  suppliers$: Observable<Supplier[]>;
  customerID$ = new BehaviorSubject<number>(0);
  @Input() set customerID (value: number) {
    this.customerID$.next(value);
  }

  @Input() supplierID: number;
  selected: Supplier;
  @Output() supplierIDChange = new EventEmitter<number>();
  @Output() selectedSupplier = new EventEmitter<Supplier>();


  constructor(private supplierService: SuppliersService, customerProdService: CustomerProductSupplierService) {
    this.suppliers$ = combineLatest([ supplierService.allSuppliers$, customerProdService.productSuppliers$, this.customerID$ ]).pipe(map(([suppliers, supplierProds, customerID]) => {
      if(this.supplierID) this.selected = suppliers.find(x => x.supplierID == this.supplierID);
      if(!customerID || supplierProds.filter(x => x.customerID == customerID).length == 0) {
        return suppliers.filter(x => x.isActive || x.supplierID == this.supplierID);  //just show all as this customer has no specific suppliers defined (or multiple customers on the order)
      } else {
        const showProds = suppliers.filter(x => this.showProduct(x, supplierProds, customerID));
        return showProds; 
      }
    }));

  }

  showProduct(supp: Supplier, customerProds: CustomerProductSupplier[], customerID: number): boolean {
    if(!supp.isActive && supp.supplierID != this.supplierID) return false;
    if(!customerID) return true;
    return customerProds.find(x => x.customerID == customerID && x.supplierID == supp.supplierID) != null;
  }

  ngOnInit(): void {
  }

  changed() {
    this.supplierID = this.selected.supplierID;
    this.supplierIDChange.emit(this.supplierID);
    this.selectedSupplier.emit(this.supplierService.getSupplier(this.supplierID));
  }
}
