<!-- <select class="form-control" id="supplier" name="supplier" [(ngModel)]="supplierID" *ngIf="(suppliers$ | async) as data" (ngModelChange)="changed()">
    <option *ngFor="let s of data" value="{{s.supplierID}}">{{s.companyName}}</option>
</select> -->

<p-dropdown [options]="(suppliers$ | async)" [(ngModel)]="selected" dataKey="supplierID" [autoDisplayFirst]="false" [style]="{width: '100%', 'border-radius': '0', 'border-color': '#ced4da' }" (onChange)="changed()">
    <ng-template pTemplate="selectedItem">
        <div class="style" *ngIf="selected">
            <div>{{ selected.companyName }}</div>
        </div>
    </ng-template>
    <ng-template let-supplier pTemplate="item">
        <div class="style">
            <div>{{ supplier.companyName }}</div>
        </div>
    </ng-template>
</p-dropdown>