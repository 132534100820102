import { Component, OnInit } from '@angular/core';
import { CustomersService, Customer } from '../../../services/customers.service';
import { SuppliersService, Supplier } from '../../../services/suppliers.service';
import { TransportsService, TransportCompany } from '../../../services/transports.service';
import { ProductsService, Product } from '../../../services/products.service';
import { Observable } from 'rxjs';
import { ReportOptions, ReportingService } from '../../../services/reporting.service';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.css']
})
export class ReportFilterComponent implements OnInit {
  customers: Observable<Customer[]>;
  suppliers: Observable<Supplier[]>;
  transports: Observable<TransportCompany[]>;
  products: Observable<Product[]>;


  constructor(private customerService: CustomersService,
    private supplierService: SuppliersService, private transportService: TransportsService,
    private productService: ProductsService, public reportingService: ReportingService) { 
      this.suppliers = this.supplierService.allSuppliers$;
      this.customers = this.customerService.allCustomers$;
      this.products = this.productService.allProducts$;
      this.transports = this.transportService.allTransports$;
    }

  ngOnInit() {
  }

}

