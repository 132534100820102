import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../../../services/products.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit {
  @Output() saveProduct: EventEmitter<Product> = new EventEmitter<Product>();
  @Input() set product(value) {
    this._product = value;
    if (this._product.productID === 0) {
      this.newProduct = true;
    }
  }
  @Output() cancelEdit: EventEmitter<void> = new EventEmitter();

  _product: Product;
  newProduct = true;

  constructor() { }

  ngOnInit() {
  }

  save() {
    this.saveProduct.emit(this._product);
  }

  cancel() {
    this.cancelEdit.emit();
  }

}
