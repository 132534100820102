import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

/*
    IMPORTANT NOTE: we cannot use HttpClient here because we have a token interceptor set up on HttpClient.

    Once user is authenticated, all other HTTP calls to the API will use HttpClient

*/

export class Credentials {
  constructor(public username: string, public password: string) { }
}

@Injectable()
export class AuthService {
  apiEndpoint = window.location.origin + '/api/authenticate';
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private router: Router) {
    if (this.isAuthenticated()) {
      this.loggedIn.next(true);
    }
  }


  login(creds: Credentials) {
    return this.http.post<String>(this.apiEndpoint, creds);
  }

  logout() {
    localStorage.removeItem('token');
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  getToken(): string {
    if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) { return ''; }
    return localStorage.getItem('token').toString();
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
    this.loggedIn.next(true);
    this.router.navigate(['/home']);
  }

  isAuthenticated(): boolean {
    return (this.getToken() !== '');
  }

}
