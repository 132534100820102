<p-dropdown [options]="(options$ | async)" [(ngModel)]="selected" dataKey="productSupplierID" [autoDisplayFirst]="false" [style]="{width: '100%', 'border-radius': '0', 'border-color': '#ced4da'}" (onChange)="onSelectChanged()">
    <ng-template pTemplate="selectedItem">
        <div class="style" *ngIf="selected">
            <div>{{ selected.supplierName }} - {{selected.productName}} @ {{selected.buyPrice}}</div>
        </div>
    </ng-template>
    <ng-template let-ps pTemplate="item">
        <div class="style">
            <div>{{ ps.supplierName }} - {{ps.productName}} @ {{ps.buyPrice}}</div>
        </div>
    </ng-template>
</p-dropdown>