<div class="row">
  <div class="col-12 mb-2">
    <button class="btn btn-primary" (click)="addTransportOrder()">New Transport Order</button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        Transport Orders
      </div>
      <div class="card-body">
        <ngx-loading [show]="loading"></ngx-loading>
        <p-table #dt [value]="orders" [paginator]="true" [rows]="20" [globalFilterFields]="['transportCode','poText','productText']">
          <ng-template pTemplate="caption">
            <div style="text-align: right">
              <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText placeholder="Enter search text" (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                style="width:auto">
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 87px"></th>
              <th style="width: 50px"></th>
              <th>Transport</th>
              <th style="width: 80px">Del. Date</th>
              <th class="d-none d-md-table-cell" style="width: 45px">Sent</th>
              <th class="d-none d-md-table-cell" style="width: 45px">Del.</th>
              <th class="d-none d-lg-table-cell" style="width: 90px">CWFO PO</th>
              <th class="d-none d-md-table-cell">Supplier / Product</th>
              <th class="d-none d-md-table-cell text-center" style="width: 120px">Delivered</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <!-- [ngClass]="{'pending-delivered': }"  highlight date passed and IsDelivered flag not set -->
              <td>
                <button class="btn btn-primary btn-sm" (click)="editOrder(item)">
                  <i class="far fa-pencil fa-fw"></i>
                </button>
                <button class="btn btn-default btn-sm" (click)="deleteOrder(item)" *ngIf="!item.sentToTransport">
                  <i class="far fa-trash-alt fa-fw"></i>
                </button>
              </td>
              <td>{{item.transportOrderID }}</td>
              <td>{{item.transportCode }}</td>
              <td>{{item.deliveryDate | date:'dd/MM/yy'}}</td>
              <td class="d-none d-md-table-cell text-center">
                <span *ngIf="item.sentToTransport">
                  <i class="fas fa-check text-success" [title]="item.dateSent | date:'dd/MM/yy HH:mm':'+2000'"></i><!-- timezones causing issue, seems to ignore the GMT+10.  Adding +2000 fixes for display-->
                </span>
                <span *ngIf="!item.sentToTransport">
                  <i class="fas fa-times text-muted"></i>
                </span>
              </td>
              <td class="d-none d-md-table-cell text-center">
                <i class="fas" [ngClass]="{'fa-check-circle text-success': item.isDelivered, 'fa-times text-muted': !item.isDelivered}"></i>
              </td>
              <td class="d-none d-lg-table-cell">
                <span [innerHTML]="item.cwfopo"></span>
              </td>
              <td class="d-none d-md-table-cell">
                <span [innerHTML]="item.products"></span>
              </td>
              <td class="d-none d-md-table-cell text-center">
                <ngb-progressbar type="info" [value]="item.deliveredQuantity" [max]="item.scheduledQuantity" title="Delivered {{item.deliveredQuantity}} / {{item.scheduledQuantity}}"></ngb-progressbar>
                <!-- {{item.deliveredQuantity}} / {{item.scheduledQuantity}} -->
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>