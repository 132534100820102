import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { TransportsService, TransportCompany } from '../../../services/transports.service';

@Component({
  selector: 'app-transports',
  templateUrl: './transports.component.html',
  styleUrls: ['./transports.component.css']
})
export class TransportsComponent implements OnInit {
  
  _allTransports:TransportCompany[];
  transports: TransportCompany[];

  currentTransport: TransportCompany;
  view = 0;
  showInactive = false;

  constructor(private service: TransportsService) { }

  ngOnInit() {
    this.service.allTransports$.subscribe(res => {
      this._allTransports = res;
      this.filterTransports();
    }, err => {
      alert(err.error);
    });
  }

  filterTransports() {
    this.transports = this._allTransports.filter(c => c.isActive || this.showInactive);
  }

  toggleShowInactive() {
    this.showInactive = !this.showInactive;
    this.filterTransports();
  }

  addNewTransport() {
    this.currentTransport = new TransportCompany();
    this.view = 1;
  }
  editTransport(t: TransportCompany) {
    this.currentTransport = Object.assign({}, t);
    this.view = 1;
  }
  onSaveTransport(transport: TransportCompany) {
    if (!transport.transportID) {
      this.createTransport(transport);
    } else {
      this.saveTransport(transport);
    }
  }
  onCancelEdit() {
    this.currentTransport = null;
    this.view = 0;
  }


  private createTransport(transport: TransportCompany) {
    this.service.createTransport(transport).subscribe(res => {
      this.view = 0;
      this.service.reloadTransports();
    }, err => {
      console.log(err);
      alert(err.error);
    });
  }

  private saveTransport(transport: TransportCompany) {
    this.service.saveTransport(transport).subscribe(res => {
      this.view = 0;
      this.service.reloadTransports();
    }, err => {
      console.log(err);
      alert(err.error);
    });
  }

}
