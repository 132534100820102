import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerProductSupplierService {

  apiEndpoint = window.location.origin + '/api/customerproduct';

  private _items: CustomerProductSupplier[];
  productSuppliers$ = new ReplaySubject<CustomerProductSupplier[]>();
  loading$ = new BehaviorSubject<boolean>(true);


  constructor(private http: HttpClient) {
    this.reload();
  }

  reload() {
    this.loading$.next(true);
    this.http.get<CustomerProductSupplier[]>(this.apiEndpoint).subscribe(res => {
      this._items = res;
      this.productSuppliers$.next(this._items);
      this.loading$.next(false);
    });
  }

  
  getCustomerProductSupplier(customerID: number, productID: number, supplierID: number) {
    return this._items.find(x => x.productID == productID && x.customerID == customerID && x.supplierID == supplierID);
  }


  addItem(customerID: number, supplierProductID: number, sellPrice: number) {
    this.http.post(this.apiEndpoint, { customerID, supplierProductID, sellPrice }).subscribe(() => {
      this.reload();
    }, error => {
      console.log(error);
    }
    );
  }

  updateItem(customerProductID: number, sellPrice: number) {
    this.http.put(this.apiEndpoint + '/' + customerProductID.toString(), {sellPrice}).subscribe(() => {
      this.reload();
    }, error => {
      console.log(error);
    });
  }

  removeItem(customerProductID: number) {
    this.http.delete(this.apiEndpoint + '/' + customerProductID.toString()).subscribe(() => {
      this.reload();
    }, error => {
      console.log(error);
    });
  }
}


export class CustomerProductSupplier {
  public customerProductID: number;
  public sellPrice: number | null;
  public customerID: number;
  public supplierProductID: number;
  public productID: number;
  public productName: string;
  public supplierID: number;
  public supplierName: string;
  public buyPrice: number | null;
}
