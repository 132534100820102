import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportingService } from '../../../services/reporting.service';

@Component({
  selector: 'app-report-group',
  templateUrl: './report-group.component.html',
  styleUrls: ['./report-group.component.css']
})
export class ReportGroupComponent implements OnInit {
  groupField: string;

  @Output() groupChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(private reportingService: ReportingService) { 
    
  }

  ngOnInit() {
    this.groupField = this.reportingService.reportOptions.groupField;
  }

  groupchange() {
    this.groupChanged.emit(this.groupField);
  }
}
