<nav class="navbar fixed-top navbar-expand-sm navbar-dark bg-primary">
    <div class="container">
        <a class="navbar-brand" [routerLink]='["/home"]'></a>
        <button class="navbar-toggler" type="button" aria-label="Toggle navigation" (click)="toggleCollapsed()">
            <span *ngIf="collapsed" class="fas fa-bars fa-2x"></span>
            <span *ngIf="!collapsed" class="fas fa-times fa-2x"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'collapse': collapsed}">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: true }'>
                    <a class="nav-link" [routerLink]='["/home"]' (click)='collapse()'>
                        <div class="icon">
                            <i class='fas fa-home'></i>
                        </div>
                        <div class='title'>Home</div>
                    </a>
                </li>
                <li class="nav-item" [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: false }'>
                    <a class="nav-link" [routerLink]='["/orders"]' (click)='collapse()'>
                        <div class="icon">
                            <i class='fas fa-file-alt'></i>
                        </div>
                        <div class='title'>Orders</div>
                    </a>
                </li>
                <li class="nav-item" [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: false }'>
                    <a class="nav-link" [routerLink]='["/transportorders"]' (click)='collapse()'>
                        <div class="icon">
                            <i class='fas fa-shipping-fast'></i>
                        </div>
                        <div class='title'>Trans Orders</div>
                    </a>
                </li>
                <li class="nav-item" [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: true }'>
                    <a class="nav-link" [routerLink]='["/customers"]' (click)='collapse()'>
                        <div class="icon">
                            <i class='fas fa-users'></i>
                        </div>
                        <div class='title'>Customers</div>
                    </a>
                </li>
                <li class="nav-item" [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: true }'>
                    <a class="nav-link" [routerLink]='["/suppliers"]' (click)='collapse()'>
                        <div class="icon">
                            <i class='fas fa-shopping-cart'></i>
                        </div>
                        <div class='title'>Suppliers</div>
                    </a>
                </li>
                <li class="nav-item" [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: true }'>
                    <a class="nav-link" [routerLink]='["/transports"]' (click)='collapse()'>
                        <div class="icon">
                            <i class='fas fa-truck'></i>
                        </div>
                        <div class='title'>Transport</div>
                    </a>
                </li>
                <li class="nav-item" [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: true }'>
                    <a class="nav-link" [routerLink]='["/products"]' (click)='collapse()'>
                        <div class="icon">
                            <i class='fas fa-database'></i>
                        </div>
                        <div class='title'>Products</div>
                    </a>
                </li>
                <li class="nav-item" [routerLinkActive]='["active"]' [routerLinkActiveOptions]='{ exact: true }'>
                    <a class="nav-link" [routerLink]='["/reports"]' (click)='collapse()'>
                        <div class="icon">
                            <i class='fas fa-file-alt'></i>
                        </div>
                        <div class='title'>Reports</div>
                    </a>
                </li>
            </ul>
            <!-- <form class="form-inline my-2 my-lg-0">
            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
          </form> -->
        </div>
    </div>
</nav>
<!--     
<div class='main-nav'>
    <div class='navbar'>
        <div class='navbar-header'>
            <button type='button' class='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse' [attr.aria-expanded]='isExpanded' (click)='toggle()'>
                <span class='sr-only'>Toggle navigation</span>
                <span class='icon-bar'></span>
                <span class='icon-bar'></span>
                <span class='icon-bar'></span>
            </button>
            <a class='navbar-brand' [routerLink]='["/"]'>
                <i class='fas fa-home'></i> Home
            </a>    
        </div>
        <div class='clearfix'></div>
        <div class='navbar-collapse collapse' [ngClass]='{ "in": isExpanded }'>
            <ul class='nav navbar-nav'>
                <li [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
                    <a [routerLink]='["/orders"]' (click)='collapse()'>
                        <p><i class='fas fa-file-alt'></i></p>
                        <p class='title'>Orders</p>
                    </a>
                </li>
                <li [routerLinkActive]='["link-active"]'>
                    <a [routerLink]='["/customers"]' (click)='collapse()'>
                        <p><i class='fas fa-users'></i></p>
                        <p class='title'>Customers</p>
                    </a>
                </li>
                <li [routerLinkActive]='["link-active"]'>
                    <a [routerLink]='["/suppliers"]' (click)='collapse()'>
                        <p><i class='fas fa-shopping-cart'></i></p>
                        <p class='title'>Suppliers</p>
                    </a>
                </li>
                <li [routerLinkActive]='["link-active"]'>
                    <a [routerLink]='["/transports"]' (click)='collapse()'>
                        <p><i class='fas fa-truck'></i></p>
                        <p class='title'>Suppliers</p>
                    </a>
                </li>
                <li [routerLinkActive]='["link-active"]'>
                    <a [routerLink]='["/products"]' (click)='collapse()'>
                        <p><i class='fas fa-database'></i></p>
                        <p class='title'>Products</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div> -->