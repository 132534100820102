import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

/**
 * Example of a Native Date adapter
 */
@Injectable()
export class NgbDateAussie extends NgbDateAdapter<Date> {

    fromModel(date: Date): NgbDateStruct {
        if (!date) {
            const tmp = new Date(date);
            return { year: tmp.getFullYear(), month: tmp.getMonth() + 1, day: tmp.getDate() };
        } else {
            return null;
        }
    }

    toModel(date: NgbDateStruct): Date {
        if (date) {
            const tmp = new Date(Date.UTC(date.year, date.month - 1, date.day));
            return tmp;
        } else {
            return null;
        }
    }
}



@Injectable()
export class NgbDateParserFormatterAussie extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split('/');
            if (dateParts.length === 1) {
                return { year: +dateParts[0], month: null, day: null };
            } else if (dateParts.length === 2) {
                return { year: +dateParts[1], month: +dateParts[0], day: null };
            } else if (dateParts.length === 3) {
                return { year: +dateParts[2], month: +dateParts[1], day: +dateParts[0] };
            }
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        let stringDate = '';
        if (date) {
            stringDate += +date.day > 0 ? this.padNumber(date.day) + '/' : '';
            stringDate += +date.month > 0 ? this.padNumber(date.month) + '/' : '';
            stringDate += date.year;
        }
        return stringDate;
    }

    padNumber(num: number) {
        return (num < 10 ? '0' + num.toString() : num.toString());
    }
}
