import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';           // for using moment in component
import { MomentModule } from 'ngx-moment';  // for moment pipes



import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { ProductsComponent } from './components/products/products/products.component';
import { ProductEditComponent } from './components/products/product-edit/product-edit.component';
import { AppRoutingModule } from './app-routing.module';
import { ProductsService } from './services/products.service';
import { TransportsComponent } from './components/transports/transports/transports.component';
import { TransportEditComponent } from './components/transports/transport-edit/transport-edit.component';
import { TransportsService } from './services/transports.service';
import { SuppliersComponent } from './components/suppliers/suppliers/suppliers.component';
import { SupplierEditComponent } from './components/suppliers/supplier-edit/supplier-edit.component';
import { SuppliersService } from './services/suppliers.service';
import { CustomersComponent } from './components/customers/customers/customers.component';
import { CustomerEditComponent } from './components/customers/customer-edit/customer-edit.component';
import { CustomersService } from './services/customers.service';
import { OrdersComponent } from './components/orders/orders/orders.component';
import { OrderEditComponent } from './components/orders/order-edit/order-edit.component';
import { OrdersService } from './services/orders.service';
import { NgbDateParserFormatterAussie, NgbDateAussie } from './common/ngbDateAussie';
import { TransportOrdersComponent } from './components/transport-order/transport-orders/transport-orders.component';
import { TransportOrderEditComponent } from './components/transport-order/transport-order-edit/transport-order-edit.component';
import { TransportOrderService } from './services/transport-order.service';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { TokenInterceptor } from './services/token.interceptor';
import { DashboardComponent } from './components/dashboard/dashboard.component';

import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import { DocumentsService } from './services/documents.service';
import { OrderDocumentsComponent } from './components/orders/order-documents/order-documents.component';

import { NgChartsModule } from 'ng2-charts';
import { DashboardService } from './services/dashboard.service';
import { DeliveryDocumentsComponent } from './components/transport-order/delivery-documents/delivery-documents.component';
import { AutoFocusDirective } from './common/auto-focus.directive';
import { ReportFilterComponent } from './components/reports/report-filter/report-filter.component';
import { ReportGroupComponent } from './components/reports/report-group/report-group.component';
import { ReportMainComponent } from './components/reports/report-main/report-main.component';
import { ReportResultsComponent } from './components/reports/report-results/report-results.component';
import { ReportingService } from './services/reporting.service';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';


import { Angular2CsvModule } from 'angular2-csv';
import { ProductSuppliersComponent } from './components/products/product-edit/product-suppliers/product-suppliers.component';
import { SelectSupplierComponent } from './components/suppliers/select-supplier/select-supplier.component';
import { CustomerSupplierProductsComponent } from './components/customers/customer-edit/customer-supplier-products/customer-supplier-products.component';
import { SelectProductsupplierComponent } from './components/products/select-productsupplier/select-productsupplier.component';
import { SelectCustomerComponent } from './components/customers/select-customer/select-customer.component';
import { SelectProductComponent } from './components/products/select-product/select-product.component';
import { SelectTransportComponent } from './components/transports/select-transport/select-transport.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    ContentHeaderComponent,
    ProductsComponent,
    ProductEditComponent,
    TransportsComponent,
    TransportEditComponent,
    SuppliersComponent,
    SupplierEditComponent,
    CustomersComponent,
    CustomerEditComponent,
    OrdersComponent,
    OrderEditComponent,
    TransportOrdersComponent,
    TransportOrderEditComponent,
    LoginComponent,
    DashboardComponent,
    OrderDocumentsComponent,
    DeliveryDocumentsComponent,
    AutoFocusDirective,
    ReportFilterComponent,
    ReportGroupComponent,
    ReportMainComponent,
    ReportResultsComponent,
    ProductSuppliersComponent,
    SelectSupplierComponent,
    CustomerSupplierProductsComponent,
    SelectProductsupplierComponent,
    SelectCustomerComponent,
    SelectProductComponent,
    SelectTransportComponent,
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    Angular2CsvModule,
    LoadingModule.forRoot({
      animationType: ANIMATION_TYPES.rotatingPlane,
      backdropBackgroundColour: 'rgba(55,55,55,0.25)',
      primaryColour: '#F52026',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    MomentModule,
    NgChartsModule,
    CalendarModule,
    BrowserAnimationsModule,
    BlockUIModule,
    TooltipModule,
    DropdownModule
  ],
  providers: [ProductsService, TransportsService, SuppliersService,
    CustomersService, OrdersService, TransportOrderService, ReportingService,
    AuthGuardService, AuthService, DocumentsService, DashboardService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: NgbDateParserFormatterAussie },
    { provide: NgbDateAdapter, useClass: NgbDateAussie }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private loggedIn = false;

  constructor(public auth: AuthService) {
    auth.loggedIn.subscribe(res => {
      this.loggedIn = res;
    });
  }

}
