import { Observable } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TransportCompany, TransportsService } from '../../../services/transports.service';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-select-transport',
  templateUrl: './select-transport.component.html',
  styleUrls: ['./select-transport.component.css']
})
export class SelectTransportComponent implements OnInit {
  transports$: Observable<TransportCompany[]>;
  selected: TransportCompany;

  @Input() transportID: number;
  @Output() transportIDChange = new EventEmitter<number>();
  @Output() selectedTransport = new EventEmitter<TransportCompany>();

  constructor(private transportService: TransportsService) { 
    this.transports$ = transportService.allTransports$.pipe(
      map(res => res.filter(x => x.isActive || x.transportID == this.transportID)),
      tap(res => this.selected = res.find(x => x.transportID == this.transportID)),
    );
  }

  ngOnInit(): void {
  }

  changed() {
    this.transportID = this.selected.transportID;
    this.transportIDChange.emit(this.transportID);
    this.selectedTransport.emit(this.transportService.getTransport(this.transportID));
  }

}
