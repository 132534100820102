import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductsComponent } from './components/products/products/products.component';
import { TransportsComponent } from './components/transports/transports/transports.component';
import { SuppliersComponent } from './components/suppliers/suppliers/suppliers.component';
import { CustomersComponent } from './components/customers/customers/customers.component';
import { OrdersComponent } from './components/orders/orders/orders.component';
import { TransportOrdersComponent } from './components/transport-order/transport-orders/transport-orders.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OrderEditComponent } from './components/orders/order-edit/order-edit.component';
import { TransportOrderEditComponent } from './components/transport-order/transport-order-edit/transport-order-edit.component';
import { ReportMainComponent } from './components/reports/report-main/report-main.component';


const appRoutes: Routes = [
  {
    path: '', component: LoginComponent, pathMatch: 'full',
    data: { title: 'Login' }
  },
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'products', component: ProductsComponent, data: { title: 'Products' }, canActivate: [AuthGuardService] },
  { path: 'transports', component: TransportsComponent, data: { title: 'Transports' }, canActivate: [AuthGuardService] },
  { path: 'suppliers', component: SuppliersComponent, data: { title: 'Suppliers' }, canActivate: [AuthGuardService] },
  { path: 'customers', component: CustomersComponent, data: { title: 'Customers' }, canActivate: [AuthGuardService] },
  { path: 'orders', component: OrdersComponent, data: { title: 'Orders' }, canActivate: [AuthGuardService] },
  { path: 'orders/:id', component: OrderEditComponent, data: { title: 'Edit Order' }, canActivate: [AuthGuardService] },
  { path: 'transportorders', component: TransportOrdersComponent, data: { title: 'Transport Orders' }, canActivate: [AuthGuardService] },
  { path: 'transportorders/:id', component: TransportOrderEditComponent, data: { title: 'Transport Orders' },
                                 canActivate: [AuthGuardService] },
  { path: 'reports', component: ReportMainComponent, data: { title: 'Reports' }, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
