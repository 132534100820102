<p-dropdown [options]="(customers$ | async)" [(ngModel)]="selected" dataKey="customerID" appendTo="body" [autoDisplayFirst]="false" [scrollHeight]="'300px'" [style]="{width: '100%', 'border-radius': '0', 'border-color': '#ced4da' }" (onChange)="changed()">
    <ng-template pTemplate="selectedItem">
        <div class="style" *ngIf="selected">
            <div>{{ selected.companyName }}</div>
        </div>
    </ng-template>
    <ng-template let-supplier pTemplate="item">
        <div class="style">
            <div>{{ supplier.companyName }}</div>
        </div>
    </ng-template>
</p-dropdown>