<p-table #dt [value]="(items$ | async)">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 40px"></th>
            <th>Supplier</th>
            <th style="width: 90px">Product</th>
            <th class="text-center" style="width: 80px">Buy $/L</th>
            <th class="text-center" style="width: 80px">Sell $/L</th>
            <th style="width: 80px"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td style="width: 40px">
                <button class="btn btn-default btn-sm btn-outlined" (click)="confirmRemove(item)">
                    <i class="far fa-trash-alt"></i>
                </button>
            </td>
            <td>
                {{item.supplierName}}
            </td>
            <td style="width: 90px">
                {{item.productName}}
            </td>
            <td style="width: 80px">
                {{item.buyPrice | number: '1.2-2'}}
            </td>
            <td style="width: 80px">
                <input type="number" class="form-control" id="newSell" name="newSell" #newSell="ngModel"
                    [(ngModel)]="item.sellPrice">
            </td>
            <td style="width: 80px">
                <button class="btn btn-success btn-sm btn-outlined" (click)="savePrice(item)" *ngIf="newSell.dirty">
                    <i class="far fa-save"></i>
                </button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <tr class="addrow flex ">
            <td class="text-center" style="width: 40px">
                <i class="far fa-plus"></i>
            </td>
            <td style="width: 356px" colspan="2"> <!-- annoyingly, have to set this else doesn't match width of table-->
                <app-select-productsupplier [(selected)]="selectedProdSupplier"></app-select-productsupplier>
            </td>
            <td style="width: 80px">
            </td>
            <td style="width: 80px">
                <input type="number" class="form-control" id="newBuy" name="newBuy" [(ngModel)]="newSellPrice">
            </td>
            <td style="width: 80px; text-align: center;">
                <button class="btn btn-success btn-sm text-white" [disabled]="!selectedProdSupplier"
                    (click)="addSupplier()">Add <i class="far fa-arrow-up"></i></button>
            </td>
        </tr>
    </ng-template>
</p-table>