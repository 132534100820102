<div class="card">

  <div class="card-header">{{!_transport.transportID ? 'New Transport Company' : 'Edit ' + _transport.companyName}}</div>
  <div class="card-body">
    <form #transportForm>

      <div class="form-group">
        <label for="code">Company Name</label>
        <input type="text" autocomplete="off" class="form-control" id="name" name="name" [(ngModel)]="_transport.companyName" required appAutoFocus>
      </div>

      <div class="row">
        <div class="form-group col-md-3">
          <label for="code">Code</label>
          <input type="text" autocomplete="off" class="form-control" id="code" name="code" [(ngModel)]="_transport.code" required>
        </div>
        <div class="form-check col-md-6 mt-3" *ngIf="(_transport.transportID)">
          <span class="switch switch-sm">
            <input type="checkbox" class="switch" id="chkActive" name="isActive" [(ngModel)]="_transport.isActive">
            <label for="chkActive">Active</label>
          </span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-8">
          <label for="contact">Contact Person</label>
          <input type="text" autocomplete="name" class="form-control" id="contact" name="contact" [(ngModel)]="_transport.contactPerson" required>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-8">
          <label for="contact">Email Address</label>
          <input type="email" autocomplete="email" class="form-control" id="email" name="email" [(ngModel)]="_transport.emailAddress">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-8">
          <label for="cc">CC Email</label>
          <input type="email" autocomplete="email" class="form-control" id="cc" name="cc" [(ngModel)]="_transport.ccEmail">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label for="contact">Phone</label>
          <input type="text" autocomplete="tel" class="form-control" id="phone" name="phone" [(ngModel)]="_transport.phone">
        </div>
        <div class="form-group col-md-4">
          <label for="altPhone">Alternate Phone</label>
          <input type="text" autocomplete="tel" class="form-control" id="altPhone" name="altPhone" [(ngModel)]="_transport.altPhone">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label for="address">Company Address</label>
          <input type="text" autocomplete="street-address" class="form-control" id="address" name="address" [(ngModel)]="_transport.address1">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label for="suburb">Suburb</label>
          <input type="text" autocomplete="off" class="form-control" id="suburb" name="suburb" [(ngModel)]="_transport.suburb">
        </div>
        <div class="form-group col-md-2">
          <label for="postcode">Postcode</label>
          <input type="number" autocomplete="postal-code" class="form-control" id="postcode" name="postcode" [(ngModel)]="_transport.postcode">
        </div>
        <div class="form-group col-md-2">
          <label for="state">State</label>
          <select class="form-control" name="state" id="state" [(ngModel)]="_transport.state">
            <option value=""></option>
            <option value="ACT">ACT</option>
            <option value="NSW">NSW</option>
            <option value="NT">NT</option>
            <option value="QLD">QLD</option>
            <option value="SA">SA</option>
            <option value="TAS">TAS</option>
            <option value="VIC">VIC</option>
            <option value="WA">WA</option>
          </select>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" (click)="save()">Save Transport</button>
      <button type="button" class="btn btn-default ms-2" (click)="cancel()">Cancel</button>
    </form>
  </div>
</div>