import { Component, OnInit } from '@angular/core';
import { AuthService, Credentials } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  creds: Credentials = new Credentials('', '');
  error: string;
  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['/home']);
    }
  }

  login() {
    this.auth.login(this.creds).subscribe(res => {
      this.auth.setToken(res.toString());
    }, err => {
      this.error = 'Invalid username or password';
    });
  }

}
