import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ReportingService {
  reportsEndpoint = window.location.origin + '/api/reports';
  public reportOptions: ReportOptions;

  constructor(private http: HttpClient) {
    this.reportOptions = new ReportOptions();
  }

  getQuantityReportData(options: ReportOptions) {
    //options.fromDate.setHours(10);   //handles +10 timezone offset
    //options.toDate.setHours(10);   //handles +10 timezone offset
    return this.http.post<ReportDisplayRow[]>(this.reportsEndpoint + '/quantity', options);
  }

  exportQuantityReportAsPDF() {
    return this.http.post(this.reportsEndpoint + '/quantity/pdf', this.reportOptions, { responseType: 'blob' });
  }
}

export class ReportOptions {
  productID: number;
  customerID: number;
  supplierID: number;
  transportID: number;
  fromDate: Date;
  toDate: Date;
  groupField: string;
}


export class ReportDisplayRow {
  customerName: string;
  supplierName: string;
  product: string;
  transportCompany: string;
  quantity: number;
  orderNumber: number;
  date: Date;
  delivered: boolean;
  groupTitle: string;
  totalRow: boolean;
}

// export class QuantityReportData {
//   customerID: number;
//   productID: number;
//   supplierID: number;
//   pickupDate: Date;
//   cancelled: boolean;
//   cancelledReason: string;
//   isDelivered: boolean;
//   transportID: number;
//   transportName: string;
//   supplierName: string;
//   customerName: string;
//   quantity: number;
//   product: string;
//   supplierOrderID: number;
// }

