import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductSupplierService {

  apiEndpoint = window.location.origin + '/api/productsupplier';

  private _items: ProductSupplier[];
  productSuppliers$ = new ReplaySubject<ProductSupplier[]>();
  loading$ = new BehaviorSubject<boolean>(true);


  constructor(private http: HttpClient) {
    this.reload();
  }

  reload() {
    this.loading$.next(true);
    this.http.get<ProductSupplier[]>(this.apiEndpoint).subscribe(res => {
      res.sort((a, b) => a.supplierName.localeCompare(b.supplierName) || a.productName.localeCompare(b.productName));
      this._items = res;
      this.productSuppliers$.next(this._items);
      this.loading$.next(false);
    });
  }

  getProductSupplier(productID: number, supplierID: number) {
    return this._items.find(x => x.productID == productID && x.supplierID == supplierID);
  }

  addProductSupplier(productID: number, supplierID: number, buyPrice: number) {
    this.http.post<ProductSupplier>(this.apiEndpoint, { productID, supplierID, buyPrice }).subscribe(() => {
      this.reload();
    }, error => {
      console.log(error);
    }
    );
  }

  updateProductSupplier(id: number, buyPrice: number) {
    this.http.put(this.apiEndpoint + '/' + id.toString(), {buyPrice}).subscribe(() => {
      this.reload();
    }, error => {
      console.log(error);
    });
  }

  removeItem(id: number) {
    this.http.delete(this.apiEndpoint + '/' + id.toString()).subscribe(() => {
      this.reload();
    }, error => {
      console.log(error);
    });
  }
}

export class ProductSupplier {
  public supplierProductID: number;
  public productID: number;
  public productName: string;
  public supplierID: number;
  public supplierName: string;
  public buyPrice: number | null;
}
