import { Component, OnInit } from '@angular/core';
import { ProductsService, Product } from '../../../services/products.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  private _allProducts: Product[];
  products: Product[];
  currentProduct: Product;
  view = 0;
  showInactive = false;
  constructor(private service: ProductsService) { }

  ngOnInit() {
    this.service.allProducts$.subscribe(res => {
      this._allProducts = res;
      this.view = 0;
      this.filterProducts();
    }, err => {
      alert(err.error);
    });
  }

  filterProducts() {
    this.products = this._allProducts.filter(c => c.isActive || this.showInactive);
  }

  toggleShowInactive() {
    this.showInactive = !this.showInactive;
    this.filterProducts();
  }

  addNewProduct() {
    this.currentProduct = new Product();
    this.view = 1;
  }
  editProduct(p: Product) {
    this.currentProduct = Object.assign({}, p);
    this.view = 1;
  }

  onSaveProduct(product: Product) {
    if (!product.productID) {
      this.service.createProduct(product);
    } else {
      this.service.saveProduct(product);
    }
  }
  onCancelEdit() {
    this.currentProduct = null;
    this.view = 0;
  }
}
