<p-blockUI [target]="dt" [blocked]="(loading$ | async)">
  <i class="pi pi-lock" style="font-size: 3rem"></i>
</p-blockUI>
<p-table #dt [value]="(items$ | async)">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 40px"></th>
        <th style="width: 202px">Supplier</th>
        <th class="text-center" style="width: 100px">Buy $/L</th>
        <th style="width: 80px"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td style="width: 40px">
          <button class="btn btn-default btn-sm btn-outlined" (click)="confirmRemove(item)" >
            <i class="far fa-trash-alt"></i>
          </button>
        </td>
        <td>
          {{item.supplierName}}
        </td>
        <td style="width: 100px">
          <input type="number" class="form-control" #newBuy="ngModel" id="newBuy" name="newBuy" [(ngModel)]="item.buyPrice" >
        </td>
        <td style="width: 80px">
          <button class="btn btn-success btn-sm btn-outlined" (click)="savePrice(item)" *ngIf="newBuy.dirty">
            <i class="far fa-save"></i>
          </button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <tr class="addrow flex">
            <td class="text-center" style="width: 40px">
              <i class="far fa-plus"></i>
            </td>
            <td style="width: 202px">
                <app-select-supplier [(supplierID)]="newSupplierID"></app-select-supplier>
            </td>
            <td style="width: 100px">
              <input type="number" class="form-control" id="newBuy" name="newBuy" [(ngModel)]="newBuyPrice" pTooltip="Leave blank to use the product buy price">
            </td>
            <td style="width: 80px; text-align: center;"><button class="btn btn-success btn-sm text-white" [disabled]="!newSupplierID" (click)="addSupplier()">Add <i class="far fa-arrow-up"></i></button></td>
        </tr>
    </ng-template>
  </p-table>