import { Component, OnInit } from '@angular/core';
import { ReportingService, ReportDisplayRow } from '../../../services/reporting.service';

@Component({
  selector: 'app-report-main',
  templateUrl: './report-main.component.html',
  styleUrls: ['./report-main.component.css']
})
export class ReportMainComponent implements OnInit {
  resultingData: ReportDisplayRow[];
  runningReport = false;

  constructor(private reportService: ReportingService) { }

  ngOnInit() {
  }

  runReport() {
    this.runningReport = true;
    this.reportService.getQuantityReportData(this.reportService.reportOptions).subscribe(res => {
      this.resultingData = res;
      this.runningReport = false;
    });
  }

  groupChanged(value: string) {
    this.reportService.reportOptions.groupField = value;
    if (this.resultingData) {
      // re-run for new grouping
    this.runReport();
    }
  }
}
