import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class CustomersService {
  apiEndpoint = window.location.origin + '/api/customers';

  private _customers: Customer[];
  allCustomers$ = new ReplaySubject<Customer[]>();


  constructor(private http: HttpClient) {
    this.reload();
  }

  reload() {
    this.http.get<Customer[]>(this.apiEndpoint).subscribe(res => {
      this._customers = res;
      this.allCustomers$.next(this._customers);
    });
  }

  getCustomer(id: number) {
    return this._customers.find(x => x.customerID == id);
  }


  createCustomer(vm: Customer) {
    return this.http.post<Customer>(this.apiEndpoint, vm);
  }

  saveCustomer(vm: Customer) {
    return this.http.put<Customer>(this.apiEndpoint + '/' + vm.customerID.toString(), vm);
  }
}



export class Customer {
  public customerID: number;
  public code: string;
  public companyName: string;
  public contactPerson: string;
  public emailAddress: string;
  public address1: string;
  public suburb: string;
  public state: string;
  public postcode: string;
  public phone: string;
  public ccEmail: string;
  public terms: string;
  public isActive: boolean;
  public productSuppliers: CustomerProductSupplier[];
}

export class CustomerProductSupplier {
  public customerID: number;
  public supplierID: number;
  public productID: number;
  public supplierProductID: number;
  public productName: string;
  public supplierName: string;
  public buyPrice: number | null;
  public sellPrice: number | null;
}
