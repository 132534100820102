import { Component, OnInit } from '@angular/core';
import { DashboardService, CountData } from '../../services/dashboard.service';
import * as moment from 'moment';
import { OrdersService } from '../../services/orders.service';
import { Router } from '@angular/router';
import { ChartData } from 'chart.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  weeklyData: Number[] = [27000, 32000, 29000, 22000, 19000, 21000];
  // stupid - the chartjs needs these colours AND the backgroundColor in dataset to do colours
  dummyColors: any[] = ['#000', '#000', '#000', '#000', '#000', '#000'];
  weeklyLabels: string[];
  weeklyQuantities: any[];
  weeklyProfits: any[];

  barQtyOptions: any = {
    legend: { display: false },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: function (value: any, index: any, values: any) {
            return parseInt(value).toLocaleString();
          }
        }
      }],
      xAxes: [{
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return parseInt(tooltipValue).toLocaleString();
        }
      }
    }
  };
  barProfitOptions: any = {
    legend: { display: false },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            return '$' + value;
          }
        }
      }],
      xAxes: [{
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45
        }
      }]
    }
  };
  barProductOptions: any = {
    legend: { display: false },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: function (value: any, index: any, values: any) {
            if(value > 1000) {
              return parseInt(value).toLocaleString();
            }
            return value.toLocaleString();
          }
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return parseInt(tooltipValue).toLocaleString();
        }
      }
    }
  };
  doughOptions: any = {
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return parseInt(tooltipValue).toLocaleString();
        }
      }
    }
  };

  txWeekData: ChartData<'doughnut'>;
  txWeekLabels: string[];

  tx90Data: ChartData<'doughnut'>;
  tx90Labels: string[];

  prodWeekDataSets: any[];
  prodWeekData: Number[];
  prodWeekLabels: string[];

  prod90DataSets: any[];
  prod90Data: Number[];
  prod90Labels: string[];

  topCustomersWeek: any[];
  topCustomers90: any[];

  topSuppliersWeek: any[];
  topSuppliers90: any[];

  todaysDeliveries: any[];


  weeklyColors = ['rgba(255, 123, 148, 0.5)', 'rgba(255, 123, 148, 0.5)', 'rgba(255, 123, 148, 0.5)',
    'rgba(255, 123, 148, 0.5)', 'rgba(102, 192, 252, 0.5)', 'rgba(255, 215, 141, 0.5)'];
  weeklyBorders = ['#FF7B94', '#FF7B94', '#FF7B94', '#FF7B94', '#66C0FC', '#FFD78D'];

  counts: CountData;

  constructor(private service: DashboardService, private orderService: OrdersService,
    private router: Router) { }

  gotoOrders(show: Number) {
    this.orderService.currentSearch = show.toString();
    this.router.navigate(['orders']);
  }

  ngOnInit() {
    this.service.getWeeklyTotals().subscribe(res => {
      this.weeklyQuantities = [{
        data: res.map(o => o.quantity),
        // label: 'Total Qty',
        // fill: false,
        backgroundColor: this.weeklyColors,
        borderColor: this.weeklyBorders,
        borderWidth: 1
      }];
      this.weeklyProfits = [{
        data: res.map(o => o.profit),
        borderWidth: 1,
        backgroundColor: this.weeklyColors,
        borderColor: this.weeklyBorders
      }];
      this.weeklyLabels = res.map(o => moment(o.weekStart).format('DD-MMM'));
    });

    this.service.getTransportWeek().subscribe(res => {
      this.txWeekLabels = res.map(o => o.description);
      this.txWeekData = {
        labels: res.map(o => o.description),
        datasets: [
          { data: res.map(o => o.quantity) }
        ]
      };
      //this.txWeekData = res.map(o => o.quantity);
    });
    this.service.getTransport90().subscribe(res => {
      this.tx90Labels = res.map(o => o.description);
      this.tx90Data = {
        labels: res.map(o => o.description),
        datasets: [
          { data: res.map(o => o.quantity) }
        ]
      };
    });

    this.service.getProductWeek().subscribe(res => {
      this.prodWeekLabels = res.map(o => o.description);
      this.prodWeekData = res.map(o => o.quantity);
      this.prodWeekDataSets = [{ data: this.prodWeekData, borderWidth: 1 }];
    });
    this.service.getProduct90().subscribe(res => {
      this.prod90Labels = res.map(o => o.description);
      this.prod90Data = res.map(o => o.quantity);
      this.prod90DataSets = [{ data: this.prod90Data, label: 'Product Qty' }];
    });

    this.service.getTopCustomerWeek().subscribe(res => {
      this.topCustomersWeek = res;
    });
    this.service.getTopCustomer90().subscribe(res => {
      this.topCustomers90 = res;
    });

    this.service.getCounts().subscribe(res => {
      this.counts = res;
    });

    this.service.getTodaysDeliveries().subscribe(res => {
      this.todaysDeliveries = res;
    });

  }

}
