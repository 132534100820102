import { CustomerProductSupplier, CustomerProductSupplierService } from './../../../services/customer-product-supplier.service';
import { BehaviorSubject, Observable, combineLatest, forkJoin } from 'rxjs';
import { Product, ProductsService } from './../../../services/products.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.css']
})
export class SelectProductComponent implements OnInit {
  products$: Observable<Product[]>;
  selected: Product;

  @Input() set customerID(value: number) {
    this.customerID$.next(value);
  }
  @Input() set supplierID(value: number) {
    this.supplierID$.next(value);
  }
  customerID$ = new BehaviorSubject<number>(0);
  supplierID$ = new BehaviorSubject<number>(0);

  @Input() productID: number;
  @Output() productIDChange = new EventEmitter<number>();
  @Output() selectedProduct = new EventEmitter<Product>();

  constructor(productService: ProductsService, customerProdService: CustomerProductSupplierService) {
    this.products$ = combineLatest([productService.allProducts$, customerProdService.productSuppliers$, this.customerID$, this.supplierID$])
      .pipe(
        map(([products, custProdSuppliers, customerID, supplierID]) => {
          this.selected = products.find(x => x.productID == this.productID);
          if (!customerID || custProdSuppliers.filter(x => x.customerID == customerID).length == 0) {
            // customer blank or there are no product/suppliers for this customer
            // shows all products which are active - or it is the currently selected product (for historical data)
            return products.filter(x => (x.isActive || x.productID == this.productID));
          } else {
            const showProds = products.filter(x => this.showProduct(x, custProdSuppliers, customerID, supplierID));
            return showProds;
          }
        }));
  }

  showProduct(prod: Product, custProdSuppliers: CustomerProductSupplier[], customerID: number, supplierID: number): boolean {
    if (!prod.isActive && prod.productID != this.productID) return false;
    if (!customerID) return true;

    if(custProdSuppliers.filter(x => x.customerID == customerID).length == 0) return true; // customer doesn't have any product/suppliers
    // check if this product is supplied to this customer by the specified supplier
    return custProdSuppliers.find(x => x.customerID == customerID && x.supplierID == supplierID && x.productID == prod.productID) != null;
  }

  ngOnInit(): void {
  }

  changed() {
    this.productID = this.selected.productID;
    this.productIDChange.emit(this.productID);
    this.selectedProduct.emit(this.selected);
  }

}
