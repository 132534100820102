<div class="card">

    <div class="card-header">{{!_supplier.supplierID ? 'New Supplier' : 'Edit ' + _supplier.companyName}}</div>
    <div class="card-body">
      <form #supplierForm="ngForm">
  
        <div class="form-group">
          <label for="code">Company Name</label>
          <input type="text" class="form-control" id="name" name="name" [(ngModel)]="_supplier.companyName" required appAutoFocus>
        </div>
  
        <div class="row">
          <div class="form-group col-md-3">
            <label for="code">Code</label>
            <input type="text" class="form-control" id="code" name="code" [(ngModel)]="_supplier.code">
          </div>
          <div class="form-check col-md-6 mt-3" *ngIf="(_supplier.supplierID)">
            <span class="switch switch-sm">
              <input type="checkbox" class="switch" id="chkActive" name="isActive" [(ngModel)]="_supplier.isActive">
              <label for="chkActive">Active</label>
            </span>
          </div>
        </div>
  
        <div class="row">
          <div class="form-group col-md-8">
            <label for="contact">Contact Person</label>
            <input type="text" class="form-control" id="contact" name="contact" [(ngModel)]="_supplier.contactPerson">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-8">
            <label for="contact">Email Address</label>
            <input type="email" class="form-control" id="email" name="email" [(ngModel)]="_supplier.emailAddress" required>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-8">
            <label for="cc">CC Email</label>
            <input type="email" class="form-control" id="cc" name="cc" [(ngModel)]="_supplier.ccEmail">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4">
            <label for="phone">Phone</label>
            <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="_supplier.phone">
          </div>
          <div class="form-group col-md-4">
            <label for="alt">Alt. Phone</label>
            <input type="text" class="form-control" id="alt" name="alt" [(ngModel)]="_supplier.altPhone">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-10">
            <label for="address">Company Address</label>
            <input type="text" class="form-control" id="address" name="address" [(ngModel)]="_supplier.address1">
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="suburb">Suburb</label>
            <input type="text" class="form-control" id="suburb" name="suburb" [(ngModel)]="_supplier.suburb">
          </div>
          <div class="form-group col-md-2">
            <label for="postcode">Postcode</label>
            <input type="number" class="form-control" id="postcode" name="postcode" [(ngModel)]="_supplier.postcode">
          </div>
          <div class="form-group col-md-2">
            <label for="state">State</label>
            <select class="form-control" name="state" id="state" [(ngModel)]="_supplier.state">
              <option value=""></option>
              <option value="ACT">ACT</option>
              <option value="NSW">NSW</option>
              <option value="NT">NT</option>
              <option value="QLD">QLD</option>
              <option value="SA">SA</option>
              <option value="TAS">TAS</option>
              <option value="VIC">VIC</option>
              <option value="WA">WA</option>
            </select>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!supplierForm.form.valid" (click)="save()">Save Supplier</button>
        <button type="button" class="btn btn-default ms-2" (click)="cancel()">Cancel</button>
      </form>
    </div>
  </div>