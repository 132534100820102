import { Customer, CustomersService } from './../../../services/customers.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.css']
})
export class SelectCustomerComponent implements OnInit {
  customers$: Observable<Customer[]>;
  selected: Customer;
  @Input() customerID: number;
  @Output() customerIDChange = new EventEmitter<number>();
  @Output() selectedCustomer = new EventEmitter<Customer>();

  constructor(private customerService: CustomersService) { 
    this.customers$ = customerService.allCustomers$.pipe(
      map(res => res.filter(x => x.isActive || x.customerID == this.customerID)),
      tap(res => this.selected = res.find(x => x.customerID == this.customerID))
    );
  }

  ngOnInit(): void {
  }

  changed() {
    this.customerID = this.selected.customerID;
    this.customerIDChange.emit(this.customerID);
    this.selectedCustomer.emit(this.customerService.getCustomer(this.customerID));
  }

}
