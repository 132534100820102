<div class="row mt-1">
  <div class="card">
    <div class="card-header">
      Group Results
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <select class="form-control" name="filter" id="filter" [(ngModel)]="groupField" (change)="groupchange()">
              <option value=""></option>
              <option value="Supplier">Supplier</option>
              <option value="Customer">Customer</option>
              <option value="Product">Fuel Type</option>
              <option value="Transport">Transport Operator</option>
            </select>
          </div>
        </div>
        <div class="col-md-9 pt-1">
            <p>Data will be ordered by this field and sub-total quantities for each value.</p>
        </div>
      </div>
    </div>
  </div>
</div>