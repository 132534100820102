import { Component, OnInit } from '@angular/core';
import { Order, OrdersService, OrderListItem, ToBeInvoicedListItem } from '../../../services/orders.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  orders: OrderListItem[];
  invoiceOrders: ToBeInvoicedListItem[];
  allOrders: OrderListItem[];
  loading = false;
  quickSearchOptions: any[] = [
    { val: 0, text: 'Show All' },
    { val: 1, text: 'This Week' },
    { val: 2, text: 'Next Week' },
    { val: 3, text: 'Last Week' },
    { val: 4, text: 'Not Delivered' },
    { val: 5, text: 'To Be Invoiced' },
    { val: 6, text: 'Missing Documents' },
  ];
  selectedSearch: string;

  constructor(private service: OrdersService, private router: Router) {
    this.selectedSearch = service.currentSearch;
  }

  ngOnInit() {
    this.loading = true;
    this.service.getOrders().subscribe(res => {
      this.loading = false;
      this.allOrders = res;
      this.doSearch();
    }, err => {
      this.loading = false;
      alert(err.error);
    });
  }

  deliveryFuture(item) {
    return moment(item.deliveryDate).isAfter(moment(), 'day') && !item.isDelivered;
  }
  deliveryOverdue(item) {
    return moment(item.deliveryDate).isBefore(moment(), 'day') && !item.isDelivered;
  }

  addNewOrder() {
    this.router.navigate(['/orders', 0]);
  }
  editOrder(o: Order) {
    this.router.navigate(['/orders', o.orderID]);
  }
  deleteOrder(order: Order) {
    if (!window.confirm('Are you sure you wish to delete this Order?')) { return; }
    this.service.deleteOrder(order).subscribe(res => {
      this.orders = this.orders.filter(o => o.orderID !== order.orderID);
    }, err => {
      console.log(err);
      alert(err.error);
    });
  }

  doSearch() {
    this.orders = new Array;  // need this so that the PrimeFaces grid resets
    this.service.currentSearch = this.selectedSearch;
    if (this.selectedSearch === '1') {  // this week
      const from = moment().day(0);
      const to = moment().day(7);
      this.orders = this.allOrders.filter((o) => moment(o.deliveryDate).isBetween(from, to));
    } else if (this.selectedSearch === '2') {  // next week
      const from = moment().day(7);
      const to = moment().day(14);
      this.orders = this.allOrders.filter((o) => moment(o.deliveryDate).isBetween(from, to));
    } else if (this.selectedSearch === '3') {  // last week
      const from = moment().day(-7);
      const to = moment().day(0);
      this.orders = this.allOrders.filter((o) => moment(o.deliveryDate).isBetween(from, to));
    } else if (this.selectedSearch === '4') {  // should have been delivered by now
      this.orders = this.allOrders.filter((o) => moment(o.deliveryDate).isBefore(moment(), 'day') && !o.isDelivered);
    } else if (this.selectedSearch === '5') {
      this.service.getToBeInvoiced().subscribe(res => {
        this.invoiceOrders = res;
      });
      // this.orders = this.allOrders.filter((o) => o.isDelivered && !o.qbInvoiceNumber);
    } else if (this.selectedSearch === '6') {
      this.orders = this.allOrders.filter((o) => o.isDelivered && !o.documentsCompleted);
    } else {
      this.orders = this.allOrders;
    }

  }
}
