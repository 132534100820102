<div *ngIf="view == 0">
  <div class="row">
    <div class="col-12 mb-2">
      <button class="btn btn-primary" (click)="addNewCustomer()">New Customer</button>
      
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          Customers
        </div>
        <div class="card-body">
          <ngx-loading [show]="loading"></ngx-loading>
          <p-table #dt [value]="customers" [paginator]="true" [rows]="20" [globalFilterFields]="['code','companyName','contactPerson', 'emailAddress']">
            <ng-template pTemplate="caption">
              
              <div style="text-align: right">
                <div class="mr-2" style="display: inline; margin-right: 50px">
                  <span class="switch switch-sm">
                    <input type="checkbox" class="switch" id="showInactive" name="showInactive" [(ngModel)]="showInactive" (click)="toggleShowInactive()">
                    <label for="showInactive">Show Inactive</label>
                  </span>      
                </div>
                <div style="display: inline;">
                  <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                  <input type="text" pInputText placeholder="Enter search text" (input)="dt.filterGlobal($any($event.target).value, 'contains')"
                    style="width:auto">  
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 50px"></th>
                <th style="width: 80px">Code</th>
                <th style="max-width: 200px">Company Name</th>
                <th class="d-none d-md-table-cell" style="max-width: 120px">Contact</th>
                <th class="d-none d-sm-table-cell" style="max-width: 150px">Email Address</th>
                <th class="d-none d-lg-table-cell" style="max-width: 100px">Phone</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr [ngClass]="{'inactive': !item.isActive}">
                <td style="text-align: center;">
                  <button class="btn btn-primary btn-sm" (click)="editCustomer(item)">
                    <i class="far fa-pencil"></i>
                  </button>
                </td>
                <td>{{item.code}}</td>
                <td>{{item.companyName}}</td>
                <td class="d-none d-md-table-cell">{{item.contactPerson}}</td>
                <td class="d-none d-sm-table-cell">{{item.emailAddress}}</td>
                <td class="d-none d-lg-table-cell">{{item.phone}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="view == 1">
  <div class="row">
    <div class="col-12">
      <app-customer-edit [customer]="currentCustomer" (saveCustomer)="onSaveCustomer($event)" (cancelEdit)="onCancelEdit()"></app-customer-edit>
    </div>
  </div>
</div>