import { Component, OnInit } from '@angular/core';
import { Customer, CustomersService } from '../../../services/customers.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  private _allCustomers: Customer[];
  customers: Customer[];
  currentCustomer: Customer;
  view = 0;
  loading = false;
  showInactive = false;

  constructor(private service: CustomersService) { }

  ngOnInit() {
    this.loading = true;
    this.service.allCustomers$.subscribe(res => {
      this._allCustomers = res;
      this.filterCustomers();
      this.loading = false;
    }, err => {
      this.loading = false;
      alert(err.error);
    });
  }

  filterCustomers() {
    this.customers = this._allCustomers.filter(c => c.isActive || this.showInactive);
  }

  toggleShowInactive() {
    this.showInactive = !this.showInactive;
    this.filterCustomers();
  }

  addNewCustomer() {
    this.currentCustomer = new Customer();
    this.view = 1;
  }
  editCustomer(c: Customer) {
    this.currentCustomer = Object.assign({}, c);
    this.view = 1;
  }
  onSaveCustomer(cust: Customer) {
    if (!cust.customerID) {
      this.createCustomer(cust);
    } else {
      this.updateCustomer(cust);
    }
  }
  onCancelEdit() {
    this.currentCustomer = null;
    this.view = 0;
  }

  //#region Private Functions creating or updating customers
  private createCustomer(cust: Customer) {
    this.service.createCustomer(cust).subscribe(res => {
      this._allCustomers.push(res);
      this.view = 0;
      this.filterCustomers();
    }, err => {
      console.log(err);
      alert(err.error);
    });
  }
  private updateCustomer(cust: Customer) {
    this.service.saveCustomer(cust).subscribe(res => {
      this._allCustomers = this._allCustomers.map(c => {
        return c.customerID === cust.customerID ? cust : c;
      });
      this.filterCustomers();
      this.view = 0;
    }, err => {
      console.log(err);
      alert(err.error);
    });
  }
  //#endregion
}
