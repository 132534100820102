<app-nav-menu></app-nav-menu>

<div class="body-content container">
  <router-outlet></router-outlet>
</div>

<!-- <div class='container-fluid'>
  <div class='row'>
    <div class='col-sm-3'>
      <app-nav-menu></app-nav-menu>
    </div>
    <div class='col-sm-9 body-content'>
      <app-content-header></app-content-header>
      <router-outlet></router-outlet>
    </div>
  </div>
</div> -->