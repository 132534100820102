<div *ngIf="view == 0">
  <div class="row">
    <div class="col-12 mb-2">
      <button class="btn btn-primary" (click)="addNewProduct()">Add Product</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          Product List
        </div>
        <div class="card-body">
          <p-table #dt [value]="products" [paginator]="true" [rows]="20" [globalFilterFields]="['code','description']">
            <ng-template pTemplate="caption">
              <div style="text-align: right">
                <div class="mr-2" style="display: inline; margin-right: 50px">
                  <span class="switch switch-sm">
                    <input type="checkbox" class="switch" id="showInactive" name="showInactive"
                      [(ngModel)]="showInactive" (click)="toggleShowInactive()">
                    <label for="showInactive">Show Inactive</label>
                  </span>
                </div>
                <div style="display: inline;">
                  <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                  <input type="text" pInputText placeholder="Enter search text"
                    (input)="dt.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 50px"></th>
                <th style="width: 80px">Code</th>
                <th style="min-width: 150px">Description</th>
                <th class="d-none d-md-table-cell text-center" style="width: 90px">Buy $/L</th>
                <th class="d-none d-md-table-cell text-center" style="width: 90px">Sell $/L</th>
                <th class="d-none d-md-table-cell text-center" style="width: 90px">Excise $/L</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr [ngClass]="{'inactive': !item.isActive}">
                <td>
                  <button class="btn btn-primary btn-sm" (click)="editProduct(item)">
                    <i class="far fa-pencil"></i>
                  </button>
                </td>
                <td>{{item.code}}</td>
                <td>{{item.description}}</td>
                <td class="d-none d-md-table-cell text-right">{{item.buyPrice | currency}}</td>
                <td class="d-none d-md-table-cell text-right">{{item.sellPrice | currency}}</td>
                <td class="d-none d-md-table-cell text-right">{{item.excise | currency}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="view == 1">
  <div class="row">
    <div class="col-12">
      <app-product-edit [product]="currentProduct" (saveProduct)="onSaveProduct($event)"
        (cancelEdit)="onCancelEdit()"></app-product-edit>
    </div>
  </div>
</div>