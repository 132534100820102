<div *ngIf="_data" class="mt-2">
  <p-table [value]="_data" #table>
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix text-right">
        <angular2csv [data]="exportData" filename="bookings" [options]="exportOptions"></angular2csv>
        <button class="btn btn-primary" (click)="exportToPDF()">
          <i class="fas fa-file-pdf"></i> Export to PDF <i class="fas fa-spinner fa-pulse" *ngIf="runningReport" aria-hidden="true"></i>
        </button>

      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 20px" *ngIf="reportService.reportOptions.groupField">&nbsp;</th>
        <th style="width: 100px">Order Number</th>
        <th *ngIf="reportService.reportOptions.groupField !== 'Customer'">Customer</th>
        <th *ngIf="reportService.reportOptions.groupField !== 'Supplier'">Supplier</th>
        <th *ngIf="reportService.reportOptions.groupField !== 'Product'">Product</th>
        <th *ngIf="reportService.reportOptions.groupField !== 'Transport'">Tranport Company</th>
        <th style="width: 100px">Date</th>
        <th style="width: 100px">Quantity</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr class="groupHeader"
        *ngIf="reportService.reportOptions.groupField && (rowIndex == 0 || (_data.length > rowIndex && rowData.groupTitle !== _data[rowIndex - 1].groupTitle))">
        <!-- group header if required -->
        <td colspan="7">
          {{rowData.groupTitle}}
        </td>
      </tr>
      <tr class="totalRow" *ngIf="rowData.totalRow">
        <td colspan="6" class="text-right">{{rowData.groupTitle}}</td>
        <td class="text-right">{{rowData.quantity | number }}</td>
      </tr>
      <tr *ngIf="!rowData.totalRow">
        <td *ngIf="reportService.reportOptions.groupField"></td>
        <td>{{rowData.orderNumber}}</td>
        <td *ngIf="reportService.reportOptions.groupField !== 'Customer'">{{rowData.customerName}}</td>
        <td *ngIf="reportService.reportOptions.groupField !== 'Supplier'">{{rowData.supplierName}}</td>
        <td *ngIf="reportService.reportOptions.groupField !== 'Product'">{{rowData.product}}</td>
        <td *ngIf="reportService.reportOptions.groupField !== 'Transport'">{{rowData.transportCompany}}</td>
        <td>{{rowData.date | date:'dd/MM/yyyy'}}</td>
        <td class="text-right">{{rowData.quantity | number }}</td>
      </tr>
      <tr class="separator" *ngIf="rowData.totalRow">
        <!-- makes a little space after a group-->
        <td colspan="7">
        </td>
      </tr>
    </ng-template>
  </p-table>

</div>