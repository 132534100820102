<div class="row">
  <div class="col-12 mb-2">
    <button class="btn btn-primary" (click)="addNewOrder()">New Order</button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        Order List
      </div>
      <div class="card-body">
        <div class="form-group row mb-2">
          <div class="col-sm-2 col-lg-1 col-form-label">Filter :</div>
          <div class="col-sm-6 col-lg-3">
            <select class="form-control" name="searchOption" [(ngModel)]="selectedSearch" (change)="doSearch()">
              <option *ngFor="let item of quickSearchOptions" [value]="item.val">{{item.text}}
              </option>
            </select>
          </div>
        </div>
        <ngx-loading [show]="loading"></ngx-loading>
        <p-table #dt [value]="orders" [paginator]="true" [rows]="20" *ngIf="selectedSearch !== '5'"
          [globalFilterFields]="['orderID', 'supplierCode', 'supplierInvoiceNumber','poNumber','productCode', 'qbInvoiceNumber', 'transportCode', 'customersText']">
          <ng-template pTemplate="caption">
            <div style="text-align: right">
              <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText placeholder="Enter search text"
                (input)="dt.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 85px"></th>
              <th class="d-table-cell d-sm-none">Details</th>
              <!-- special cell containing data for mobile -->
              <th class="d-none d-sm-table-cell" style="width: 85px" [pSortableColumn]="'orderID'">CWFO # <p-sortIcon
                  [field]="'orderID'"></p-sortIcon></th>
              <th class="d-none d-sm-table-cell" style="width: 90px" [pSortableColumn]="'deliveryDate'">Del. Date
                <p-sortIcon [field]="'deliveryDate'"></p-sortIcon></th>
              <th class="d-none d-sm-table-cell" style="min-width: 90px" [pSortableColumn]="'supplierCode'">Supplier
                <p-sortIcon [field]="'supplierCode'"></p-sortIcon></th>
              <!-- <th class="d-none d-lg-table-cell" style="width: 70px">Supp. Inv.</th> -->
              <th class="d-none d-md-table-cell" style="min-width: 80px" [pSortableColumn]="'transportCode'">Transport
                <p-sortIcon [field]="'transportCode'"></p-sortIcon></th>
              <th class="d-none d-md-table-cell" style="width: 82px" [pSortableColumn]="'productCode'">Product
                <p-sortIcon [field]="'productCode'"></p-sortIcon></th>
              <th class="d-none d-lg-table-cell text-center" style="width: 90px">Total Qty </th>
              <th class="d-none d-md-table-cell" [pSortableColumn]="'customersText'">Customer <p-sortIcon
                  [field]="'customersText'"></p-sortIcon></th>
              <th class="d-none d-md-table-cell" style="width: 90px"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr [ngClass]="{'cancelled': item.isCancelled}">
              <!-- [ngClass]="{'pending-delivered': }"  highlight date passed and IsDelivered flag not set -->
              <td>
                <button class="btn btn-primary btn-sm" (click)="editOrder(item)">
                  <i class="fa-regular fa-pencil fa-fw"></i>
                </button>
                <button class="btn btn-default btn-sm ms-2" (click)="deleteOrder(item)"
                  *ngIf="!(item.transportCode || item.sentToSupplier)">
                  <i class="far fa-trash-alt fa-fw"></i>
                </button>
              </td>
              <td class="d-table-cell d-sm-none">
                <!-- special cell containing data for mobile -->
                <div class="row">
                  <div class="col-4 font-weight-bold">{{item.orderID}}</div>
                  <div class="col-4 text-center">{{item.deliveryDate | date:'dd/MM/yy'}}</div>
                  <div class="col-4 text-right">{{item.transportCode}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 font-weight-bold"><i class="far fa-shopping-cart"></i></div>
                  <div class="col-10">{{item.supplierCode}}</div>
                </div>
                <div class="row">
                  <div class="col-2 font-weight-bold"><i class="far fa-users"></i></div>
                  <div class="col-10">
                    <span [innerHTML]="item.customersText"></span>
                  </div>
                </div>
              </td>
              <td class="d-none d-sm-table-cell">{{item.orderID}}</td>
              <td class="d-none d-sm-table-cell">{{item.deliveryDate | date:'dd/MM/yy'}}</td>
              <td class="d-none d-sm-table-cell">
                <div class="d-flex invoice-cell">
                  <span>{{item.supplierCode}}</span> <i class="fas fa-file-invoice-dollar text-success" *ngIf="item.supplierInvoiceNumber" [pTooltip]="item.supplierInvoiceNumber"></i>
                </div>
              </td>
              <td class="d-none d-md-table-cell">
                <div class="d-flex invoice-cell">
                  <span>{{item.transportCode}}</span> <i class="fas fa-file-invoice-dollar text-success" [pTooltip]="item.transportInvoiceNumber" *ngIf="item.transportInvoiceNumber"></i>
                </div>
              </td>
              <td class="d-none d-md-table-cell">{{item.productCode}}</td>
              <td class="d-none d-lg-table-cell text-center">
                <ngb-progressbar [type]="item.isDelivered ? 'success' : 'info'"
                  [value]="item.isDelivered && item.exGate ? item.totalQuantity : item.deliveredQuantity"
                  [max]="item.totalQuantity"
                  title="Delivered {{item.isDelivered && item.exGate ? item.totalQuantity : item.deliveredQuantity}} / {{item.totalQuantity}}"></ngb-progressbar>
              </td>
              <td class="d-none d-md-table-cell">
                <span>{{item.customersText}} [{{item.totalQuantity}}] </span>
              </td>
              <td class="d-none d-md-table-cell" style="font-size: 1.2em">
                <i class="fas fa-fw fa-truck-moving" *ngIf="!item.exGate" [ngClass]="{'text-success': item.isDelivered, 
                                                        'text-muted': deliveryFuture(item), 
                                                        'text-warning': deliveryOverdue(item) }"
                  [title]="item.isDelivered ? 'Delivered' : 'Not Delivered'"></i>
                <span *ngIf="item.exGate" style="width: 20px; display:inline-block">&nbsp;</span>
                <!-- spacer so that remaining icons are lined up-->

                <i class="fas fa-fw ml-1" [ngClass]="{ 'fa-file': !item.isDelivered, 
                                            'fa-file-exclamation text-danger': item.isDelivered && !item.documentsCompleted, 
                                            'fa-file-check text-success': item.isDelivered && item.documentsCompleted, 
                                            'text-muted': deliveryFuture(item) }" title="Documents"></i>
                <i class="fas fa-fw fa-usd-circle ml-1" [ngClass]="{'text-success': item.invoicingComplete, 'text-danger': item.isDelivered && !item.invoicingComplete,
                                                                 'text-muted': deliveryFuture(item) }"
                  [title]="item.qbInvoiceNumbers || 'Not Invoiced'"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <!-- special format of the grid for To Be Invoiced items -->
        <p-table #dt [value]="invoiceOrders" [paginator]="true" [rows]="20" *ngIf="selectedSearch === '5'">
          <ng-template pTemplate="caption">
            <div style="text-align: right">
              <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
              <input type="text" pInputText placeholder="Enter search text"
                (input)="dt.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 50px"></th>
              <th style="width: 70px">CWFO #</th>
              <th class="d-none d-md-table-cell">Customer</th>
              <th class="d-none d-lg-table-cell" style="min-width: 90px">Customer PO</th>
              <th class="d-none d-md-table-cell">Del. Date</th>
              <th class="d-none d-md-table-cell" style="max-width: 100px">Product</th>
              <th class="d-none d-md-table-cell text-center" style="width: 80px">Delivered Qty</th>
              <th class="d-none d-md-table-cell text-center" style="width: 90px">Sell Price</th>
              <th class="d-none d-md-table-cell text-center" style="width: 90px">Excise</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>
                <button class="btn btn-primary btn-sm" (click)="editOrder(item)">
                  <i class="far fa-pencil fa-fw"></i>
                </button>
              </td>
              <td>{{item.orderID}}</td>
              <td class="d-none d-sm-table-cell">{{item.customerName}}</td>
              <td class="d-none d-lg-table-cell">{{item.customerPO}}</td>
              <td class="d-none d-md-table-cell">{{item.deliveryDate | date:'dd/MM/yy'}}</td>
              <td class="d-none d-md-table-cell">{{item.productCode}}</td>
              <td class="d-none d-md-table-cell text-right">{{item.deliveredQuantity}}</td>
              <td class="d-none d-md-table-cell text-right">{{item.sellPrice}}</td>
              <td class="d-none d-md-table-cell">{{item.excise}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>