import { Injectable } from '@angular/core';
import { ProductsService } from './products.service';
import { CustomerProductSupplierService } from './customer-product-supplier.service';
import { ProductSupplierService } from './product-supplier.service';

@Injectable({
  providedIn: 'root'
})
export class ProductPriceService {

  constructor(private prods: ProductsService, private custProdSupp: CustomerProductSupplierService, private prodSupps: ProductSupplierService) { }

  // returns the price for the specified product for the specified customer
  // if the customer has a specific price for this product, that is returned
  // otherwise the default price for the product is returned
  getPricingDetails(productID: number, customerID: number, supplierID: number): ProductPricing {
    const prod = this.prods.getProduct(productID);
    const productSupplier = this.prodSupps.getProductSupplier(productID, customerID);
    const customerSupplier = this.custProdSupp.getCustomerProductSupplier(customerID, productID, supplierID);
    
    return {
      buyPrice : productSupplier ? productSupplier.buyPrice : prod.buyPrice,
      sellPrice: customerSupplier ? customerSupplier.sellPrice : prod.sellPrice,
      excise: prod.excise
    };
  }

}

export interface ProductPricing {
  buyPrice: number;
  sellPrice: number;
  excise: number;
}
