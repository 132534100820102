import { Component, OnInit, Input } from '@angular/core';
import { Delivery } from '../../../services/transport-order.service';
import { DocumentsService, AzureDocument } from '../../../services/documents.service';

@Component({
  selector: 'app-delivery-documents',
  templateUrl: './delivery-documents.component.html',
  styleUrls: ['./delivery-documents.component.css']
})
export class DeliveryDocumentsComponent implements OnInit {
  _delivery: Delivery;
  @Input() set delivery(value: Delivery) {
    this._delivery = value;
    this.loadDocuments();
  }
  documents: AzureDocument[];
  loading = false;
  showSaveMessage = false;

  constructor(private docService: DocumentsService) {
    this.loading = true;
  }

  ngOnInit() {
  }

  private loadDocuments() {
    if (!this._delivery.transportOrderDeliveryID) {
      this.loading = false;
      this.showSaveMessage = true;
    } else {
      this.showSaveMessage = false;
      this.docService.getDeliveryDocuments(this._delivery.transportOrderDeliveryID.toString()).subscribe(res => {
        this.documents = res;
        this.loading = false;
      }, err => {
        this.loading = false;
      });
    }
  }

  handleFileInput(files: FileList, documentID: number) { // called each time file input changes
    if (files.item(0) === undefined) {
      return;
    }
    this.loading = true;
    // default to the person's container unless the container property has been explicitly set
    this.docService.uploadDocument(documentID, files.item(0), 2).subscribe(res => {
      const updateDoc = this.documents.find(o => o.documentID === res.documentID);
      updateDoc.dateUploadedUTC = res.dateUploadedUTC;
      updateDoc.downloadURL = res.downloadURL;
      this.loading = false;
    }, err => {
      console.log(err);
      alert(err.error);
      this.loading = false;
    });
  }

  setIgnore(documentID: number) {
    this.loading = true;
    this.docService.setIgnoreMissing(documentID, 2).subscribe(() => {
      const updateDoc = this.documents.find(o => o.documentID === documentID);
      updateDoc.ignoreMissing = true;
      this.loading = false;
    }, err => {
      console.log(err);
      this.loading = false;
    });
  }
}
