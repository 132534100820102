import { Component, OnInit, ViewChild } from '@angular/core';
import { TransportOrderService, TransportOrderListItem } from '../../../services/transport-order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transport-orders',
  templateUrl: './transport-orders.component.html',
  styleUrls: ['./transport-orders.component.css']
})
export class TransportOrdersComponent implements OnInit {
  orders: TransportOrderListItem[];
  loading = false;
  constructor(private service: TransportOrderService, private router: Router) { }

  ngOnInit() {
    this.loadOrders();
  }

  private loadOrders() {
    this.loading = true;
    this.service.getOrders().subscribe(res => {
      this.orders = res;
      this.loading = false;
    }, err => {
      this.loading = false;
      alert(err.error);
    });
  }

  addTransportOrder() {
    this.router.navigate(['/transportorders', 0]);
  }
  editOrder(o: TransportOrderListItem) {
    this.router.navigate(['/transportorders', o.transportOrderID]);
  }

  deleteOrder(order: TransportOrderListItem) {
    if (!window.confirm('Are you sure you wish to delete this Transport Order?')) { return; }
    this.loading = true;
    this.service.deleteOrder(order.transportOrderID).subscribe(res => {
      this.loading = false;
      this.orders = this.orders.filter(o => o.transportOrderID !== order.transportOrderID);
    }, err => {
      this.loading = false;
      console.log(err);
      alert(err.error);
    });
  }

}
