import { Component, OnInit, Input } from '@angular/core';
import { ReportingService, ReportDisplayRow } from '../../../services/reporting.service';

import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-report-results',
  templateUrl: './report-results.component.html',
  styleUrls: ['./report-results.component.css']
})
export class ReportResultsComponent implements OnInit {
  exportData: ExportData[];
  exportOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showTitle: false,
    headers: ['Supplier', 'Customer', 'Product', 'Order Number', 'Date', 'Quantity', 'Transport' ],
    showLables: true,
    useBom: true,
    removeNewLines: false,
    keys: ['supplier', 'customer', 'product', 'orderNumber', 'date', 'quantity', 'transport']
  };
  runningReport = false;

  _data: ReportDisplayRow[];
  @Input() set data(value: ReportDisplayRow[]) {
    this._data = value;
    this.createExportData();
    this.rebuildDisplayData();
  }

  constructor(public reportService: ReportingService) { }

  ngOnInit() {
  }

  rebuildDisplayData() {
    // determine the columns to display
  }


  createExportData() {
    this.exportData = (this._data || []).map(x => new ExportData(x));
  }

  exportToPDF() {
    var mediaType = 'application/pdf';
    this.runningReport = true;
    this.reportService.exportQuantityReportAsPDF().subscribe(res => {
      var blob = new Blob([res], { type: mediaType });
      saveAs(blob, 'report.pdf');
      this.runningReport = false;
      // saveAs(blob, 'report.pdf');
    });
  }
}


class ExportData {
  supplier: string;
  customer: string;
  product: string;
  orderNumber: string;
  transport: string;
  date: string;
  quantity: number;

  public constructor(item: ReportDisplayRow) {
    this.date = moment(item.date).format('DD/MM/YYYY');
    this.supplier = item.supplierName;
    this.customer = item.customerName;
    this.product = item.product;
    this.transport = item.transportCompany;
    this.orderNumber = item.orderNumber.toString();
    this.quantity = item.quantity;
  }
}
