<div class="row">
  <div class="card">
    <div class="card-header">
      Filter options
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="dtFrom">Date From</label>
            <span class="ms-2">
              <p-calendar [(ngModel)]="reportingService.reportOptions.fromDate" name="delivery" [required]="true"
                dateFormat="dd/mm/yy" [showIcon]="true" icon="far fa-calendar" [selectOtherMonths]="true"></p-calendar>
            </span>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="dtFrom">Date To</label>
            <span class="ms-2">
              <p-calendar [(ngModel)]="reportingService.reportOptions.toDate" name="delivery" [required]="true"
                dateFormat="dd/mm/yy" [showIcon]="true" icon="far fa-calendar" [selectOtherMonths]="true"></p-calendar>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="form-group">
            <label for="fueltype">Fuel Type</label>
            <select class="form-control" name="fueltype" id="fueltype"
              [(ngModel)]="reportingService.reportOptions.productID">
              <option value="0"></option>
              <option *ngFor="let p of products | async" value="{{p.productID}}">{{p.description}}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="form-group">
            <label for="supplier">Supplier</label>
            <select class="form-control" name="supplier" id="supplier"
              [(ngModel)]="reportingService.reportOptions.supplierID">
              <option value="0"></option>
              <option *ngFor="let s of suppliers | async" value="{{s.supplierID}}">{{s.companyName}}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="form-group">
            <label for="customer">Customer</label>
            <select class="form-control" name="customer" id="customer"
              [(ngModel)]="reportingService.reportOptions.customerID">
              <option value="0"></option>
              <option *ngFor="let c of customers | async" value="{{c.customerID}}">{{c.companyName}}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="form-group">
            <label for="transport">Transport Operator</label>
            <select class="form-control" name="transport" id="transport"
              [(ngModel)]="reportingService.reportOptions.transportID">
              <option value="0"></option>
              <option *ngFor="let t of transports | async" value="{{t.transportID}}">{{t.companyName}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>