import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TransportOrderService {
  apiEndpoint = window.location.origin + '/api/transorders';

  constructor(private http: HttpClient) { }

  getOrders() {
    return this.http.get<TransportOrderListItem[]>(this.apiEndpoint);
  }

  getOrder(id: string) {
    return this.http.get<TransportOrder>(this.apiEndpoint + '/' + id).pipe(tap((item) => {
      item.deliveries.forEach((d) => d.deliveryDate = new Date(d.deliveryDate));
    }));
  }

  createOrder(vm: TransportOrder) {
    return this.http.post<TransportOrder>(this.apiEndpoint, vm);
  }

  saveOrder(vm: TransportOrder) {
    return this.http.put<TransportOrder>(this.apiEndpoint + '/' + vm.transportOrderID.toString(), vm);
  }

  sendOrder(vm: TransportOrder) {
    return this.http.post<TransportOrder>(this.apiEndpoint + '/' + vm.transportOrderID.toString() + '/send', vm);
  }

  generateOrder(vm: TransportOrder) {
    return this.http.post<string>(this.apiEndpoint + '/' + vm.transportOrderID.toString() + '/generate', vm);
  }

  deleteOrder(id: number) {
    return this.http.delete(this.apiEndpoint + '/' + id.toString());
  }

}


export class TransportOrderListItem {
  public transportOrderID: number;
  public transportCode: string;
  public sentToTransport: boolean;
  public dateSent: Date;
  public isDelivered: boolean;
  public deliveryDate: Date;
  public isMultipleDelivery: boolean;
  public scheduledQuantity: number;
  public deliveredQuantity: number;
  public products: string;
  public cwfopo: string;
  // public items: TransportOrderItem[];

  /* typescript only data */
  public poText: string;
  public productText: string;
}

export class TransportOrder {
  public transportOrderID: number;
  public transportID: number;
  public transportCompanyName: string;
  public dateCreate: Date;
  public sentToTransport: boolean;
  public dateSent: Date;
  public specialInstructions: string;
  public items: TransportOrderItem[];
  public isDelivered: boolean;
  public deliveryDate: Date;
  public deliveries: Delivery[];
  public isMultipleDelivery: boolean;


  constructor() {
    this.items = [];
  }


}
export class TransportOrderItem {
  public transportOrderItemID: number;
  public supplierOrderID: number;
  public supplierID: number;
  public supplierCode: string;
  public productID: number;
  public productCode: string;
  public quantity: number;
  public cost: number;
  public deliveryDate: Date;
  public customerIDs: number[];
}

export class Delivery {
    public transportOrderDeliveryID: number;
    public customerID: number;
    public deliveryDate: Date;
    public quantity: number;
    public documentsComplete: boolean;
}

